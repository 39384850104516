import { Button } from '@mui/material';
import { t } from 'i18next';
import { useState } from 'react';
import { capitalizeFirstLetter } from '../../../common/common';
import AddBusiness from './AddBusiness';

const AddOnlineBusiness = (props) => {
  const {
    onlinePlatFrom,
    setBusinessAddInProgress,
    businessAddInProgress,
    setIsBusinessSuccessFullyAdded,
  } = props;

  const [businessAdded, setBusinessAdded] = useState(false);

  const addBusinessLocation = (data) => {
    setBusinessAdded(true);
    setBusinessAddInProgress({
      ...businessAddInProgress,
      isOnline: true,
    });
  };

  return (
    <div className="w-full">
      {!businessAddInProgress.isOnline &&
        onlinePlatFrom?.length > 0 &&
        onlinePlatFrom?.map((item, index) => {
          return (
            <div
              className="border-1 flex justify-between mb-28 items-center border-solid !border-darkgreen rounded-md mx-auto py-6 px-16"
              key={index}
            >
              <div className="flex items-center">
                <img
                  src={item?.logo}
                  alt={item?.name}
                  className="md:max-w-24 max-w-24 md:mr-20 mr-10 rounded-full"
                />
                <span className="block md:text-16 text-14 font-bold">
                  {capitalizeFirstLetter(item?.name)}
                </span>
              </div>
              <div className="flex items-center">
                <Button
                  className="inline-block align-middle rounded-3xl md:text-14 py-1 px-12 font-medium disabled:text-black min-h-24"
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={() => addBusinessLocation(item)}
                >
                  <span>
                    {item?.name === 'website'
                      ? t('manageBusiness.button.addBusiness')
                      : t('manageBusiness.button.addProduct')}
                  </span>
                </Button>
              </div>
            </div>
          );
        })}
      {businessAdded && (
        <AddBusiness
          setBusinessAdded={setBusinessAdded}
          setIsBusinessSuccessFullyAdded={setIsBusinessSuccessFullyAdded}
          setBusinessAddInProgress={setBusinessAddInProgress}
        />
      )}
    </div>
  );
};

export default AddOnlineBusiness;
