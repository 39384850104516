import React, { useEffect, useState } from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import manageBusinessData from 'src/app/main/query/manageBusiness';
import { handleApiRequest } from 'src/app/main/common/common';
import { useSelector } from 'react-redux';
import { selectDashboardData } from 'app/store/userSlice';
import { useSnackbar } from 'notistack';
import { t } from 'i18next';

const CircularProgressCustom = ({ value }) => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        value={100}
        size={90}
        thickness={6}
        sx={{
          color: '#f0e6fa',
        }}
      />
      <CircularProgress
        variant="determinate"
        value={value}
        size={90}
        thickness={6}
        sx={{
          color: '#8B78DC',
          position: 'absolute',
          left: 0,
        }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          className="font-bold text-12"
          variant="caption"
          component="div"
          color="text.primary"
        >
          {`${value}%`}
        </Typography>
      </Box>
    </Box>
  );
};

const PlanUsage = () => {
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [planUsageData, setPlanUsageData] = useState();

  useEffect(() => {
    const fetchPlanUsageData = async () => {
      try {
        setLoading(true);
        const payload = {
          query: manageBusinessData.getUserBusinessesPackageUsage,
          variables: {
            businessId: parseInt(userCurrentBusinessDetails?.id, 10),
            productType: 'quick_campaigns',
          },
        };
        const result = await handleApiRequest(payload);
        setLoading(false);
        if (result?.getUserBusinessesPackageUsage?.status === 200) {
          const planData = result?.getUserBusinessesPackageUsage?.data?.dataUsage;
          if (planData) {
            setPlanUsageData(JSON.parse(planData));
          }
        } else {
          enqueueSnackbar(result?.getUserBusinessesPackageUsage?.message, {
            variant: 'error',
            autoHideDuration: 2000,
          });
        }
      } catch (error) {
        setLoading(false);
        console.error('Error fetching plan usage data:', error);
      }
    };

    if (userCurrentBusinessDetails?.id) {
      fetchPlanUsageData();
    }
  }, [userCurrentBusinessDetails?.id, enqueueSnackbar]);

  return (
    <div className="p-24">
      <Typography className="font-bold text-20 mb-12">{t('planUsage.title')}</Typography>
      {planUsageData?.length > 0 &&
        planUsageData?.map((item, i) => {
          return (
            <div className="bg-white p-16 max-w-320 flex gap-12 items-center mb-8 rounded-md">
              <CircularProgressCustom
                value={((item.usedCount * 100) / item.totalCount).toFixed(2)}
              />
              <div>
                <Typography className="font-semibold text-16">{item?.viewName}</Typography>
                <Typography className="font-medium pt-4">
                  {t('planUsage.sent')} {item?.usedCount} / {item?.totalCount}{' '}
                  {t('planUsage.total')}
                </Typography>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default PlanUsage;
