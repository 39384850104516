const getQuickHubProducts = `
query($countryId: Int,$packageInterval: String,$packageTypeId: Int){
  getQuickHubProducts(countryId: $countryId, packageInterval: $packageInterval, packageTypeId: $packageTypeId){
    message
    status
    data{
      id
      name
      image
      description
      featureDetails
      packageIndex
      currency
      packageInterval
      packageBundle
      stripePriceId
      razorpayPlanId
      offerAmount
      packageTypeId
      monthlyAmount
      amount
      yearlyAmount
    }
  }
}
`;

const getSurpriseMeCard = `
query($isHomeScreen: Boolean!,$userBusinessId: Int!){
  getSurpriseMeCard(isHomeScreen: $isHomeScreen, userBusinessId: $userBusinessId){
    message
    status
    data{
      id
      button
      description
      buttonColor
      image
    }
  }
}
`;

const getQuickHubOverAllSummary = `
query($businessId: Int!){
  getQuickHubOverAllSummary(businessId: $businessId){
    message
    status
    data {
      overallSummary {
        key
        value
        icon
        backgroundColor
      }
    }
  }
}
`;

const quickHubOverAllFullReport = `
query($businessId: Int!){
  quickHubOverAllFullReport(businessId: $businessId){
    message
    status
    data{
      url
      timestamp
    }
  }
}
`;

const getSpecialOfferForUserBusiness = `
query($userBusinessId: Int!){
  getSpecialOfferForUserBusiness(userBusinessId: $userBusinessId){
    message
    status
    data {
      id
      backgroundColor
      name
      description
      icons
      button {
        name
        buttonColor
      }
      offerAmount
      packageInterval
      discount
      packageId
      packageBundle
      amount
      razorpayPlanId
      stripePriceId
    }
  }
}
`;

const getQuickHubProductSnapShotData = `
query($businessId: Int!){
  getQuickHubProductSnapShotData(businessId: $businessId){
    message
    status
    data {
      id
      type
      dataPoint {
       sharedPostCount
       totalEngagementsCount
       todayConversation
       todayReview
       totalConversation
       smsSent
       emailSent
       whatsAppSent
       totalReviews{
        count
        newReviews
       }
      totalReviewsRating{
        count
        totalReviewRatingsUpToGo
      }
      }
      pendingTask {
        totalProgressCount
        progressCount
        card {
          name
          button
          screen
          portalURL
          onlineIndex
          physicalIndex
          isOfflineSupport
          isOnlineSupport
        }
      }
      whatsNewCard
    }
  }
}
`;

const getCombo = `query ($countryId: Int!, $interval: String!, $packageId: Int, $packageTypeId: [Int!], $stripeIds: [String!]){
   getCombo(countryId: $countryId, interval: $interval, packageId: $packageId, packageTypeId: $packageTypeId, stripeIds: $stripeIds){
     message
     status
     data{
      amount
      discount
      expireDate
      isOffer
      name
      offerAmount
      packageId
      packagesName{
        id
        name
      }
      razorpayPlanId
      startDate
      stripeIds
      stripePriceId
      tax
     }
   }
  }`;

const redirectPortalForRazorpayOneTimePayment = `mutation ($amount: Float!, $billingCycle: String!, 
    $isCombo: Boolean!, $packageId: Int!, $userBusinessId: Int){
    redirectPortalForRazorpayOneTimePayment(
    amount: $amount,
    billingCycle: $billingCycle,
    isCombo: $isCombo,
    packageId: $packageId,
    userBusinessId: $userBusinessId
    ){
    status
    message
    data{
    url
    }
  }
}`;

const redirectPortalForSubscription = `mutation redirectPortalForSubscription($countryId: Int!, $priceId: String, 
    $stripePriceIds: [String!], $userBusinessId: Int){
    redirectPortalForSubscription(
    countryId: $countryId
    priceId: $priceId
    stripePriceIds: $stripePriceIds
    userBusinessId: $userBusinessId){
      message
      status
      data{
      url
    }
    }
  }`;

const getOverallRatingAndReview = `query getOverallRatingAndReview($businessId: Int, $domain: String, $pageNumber: Int, $pageSize: Int){
    getOverallRatingAndReview(businessId: $businessId, domain: $domain, pageNumber: $pageNumber, pageSize: $pageSize){
      message
      status
      data{
      review
      reviewText
      reviewBackgroundColor
      rating
      ratingText
      ratingBackgroundColor
      todayReviews
      todaysReviewText
      todaysReviewBackgroundColor
    }
      id
      uts
    }
  }`;

const getQuickCampaignDataPoint = `query getQuickCampaignDataPoint($businessId: Int){
    getQuickCampaignDataPoint(businessId: $businessId){
      message
      status
      data{
        key
        backgroundColor
        icon
        value
      }
    }
  }`;

const quickHub = {
  getQuickHubProducts,
  getSurpriseMeCard,
  getQuickHubOverAllSummary,
  quickHubOverAllFullReport,
  getSpecialOfferForUserBusiness,
  getQuickHubProductSnapShotData,
  getCombo,
  redirectPortalForRazorpayOneTimePayment,
  redirectPortalForSubscription,
  getOverallRatingAndReview,
  getQuickCampaignDataPoint,
};

export default quickHub;
