import { yupResolver } from '@hookform/resolvers/yup';
import { Button, CircularProgress, Paper, TextField, Typography } from '@mui/material';
import { t } from 'i18next';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import ReactFlagsSelect from 'react-flags-select';
import { useLocation } from 'react-router-dom';
import history from '@history';
import _ from '@lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import userService from 'src/app/services';
import { getCountryData, setUserData } from 'app/store/userSlice';
import {
  checkEmailExistOrNot,
  checkPhoneNumberExistOrNot,
  getDataToServerForAuth,
  getDecryptData,
  getEncryptedData,
  sendOTPOnEmailVerification,
  sendOTPOnPhoneNumberVerification,
} from '../common/common';
import queryData from '../query/common';

const UserInformation = () => {
  const schema = yup.object().shape({
    email: yup.string().email(t('signInPage.formField.email.validationMessages.validEmail')).trim(),
    firstName: yup
      .string()
      .required('First name is required')
      .max(15, 'First Name is too Long - should be 15 Characters Maximum.')
      .matches(
        /^[a-zA-Z]+$/,
        '* Special characters, Space and Numbers are not allowed in First Name.'
      ),
    lastName: yup
      .string()
      .required('Last name is required')
      .max(15, 'Last Name is too Long - should be 15 Characters Maximum.')
      .matches(
        /^[a-zA-Z]+$/,
        '* Special characters, Space and Numbers are not allowed in Last Name.'
      ),
    phoneNumber: yup
      .string()
      .required('Phone Number is required')
      .matches(/^\d{10,15}$/, 'Phone Number must be a valid number between 10 to 15 digits'),
  });

  const location = useLocation();
  const [selected, setSelected] = useState();
  const [countryIsoData, setCountryIsoData] = useState([]);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const countriesData = useSelector(getCountryData);
  const [ipBasedData, setIpBasedData] = useState(
    localStorage.getItem('ipData') ? JSON.parse(getDecryptData(localStorage.getItem('ipData'))) : ''
  );
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { control, formState, handleSubmit, setError, clearErrors, setValue, getValues } = useForm({
    mode: 'onChange',
    defaultValues: {
      firstName: location?.state?.firstName || '',
      lastName: location?.state?.lastName || '',
      phoneNumber: location?.state?.phoneNumber || '',
    },
    resolver: yupResolver(schema),
  });
  const loginUserData = userService.getUserData();

  const { isValid, dirtyFields, errors } = formState;

  const customLabels = useMemo(() => {
    if (!countriesData) {
      return {};
    }

    return countriesData.reduce((acc, country) => {
      acc[country.isoCode] = {
        primary: country.name,
        secondary: `+${country.countryCode}`,
        isoCode: country.isoCode,
        countryId: country?.id,
      };
      return acc;
    }, {});
  }, [countriesData]);

  useEffect(() => {
    if (loginUserData?.roles?.id === 2) {
      history.push('/business/dashboard');
    } else if (!location?.state) {
      history.push('sign-up');
    }
  }, [location?.state, loginUserData?.roles]);

  useEffect(() => {
    if (ipBasedData && ipBasedData.countryCode) {
      const matchingLabel = Object.entries(customLabels)?.find(
        ([key, value]) => value.secondary === `+${ipBasedData.countryCode}`
      );
      if (matchingLabel) {
        setSelected(matchingLabel[1].isoCode);
      }
    }
  }, [ipBasedData, customLabels]);

  useEffect(() => {
    if (countriesData) {
      const isoCode = countriesData.map((country) => country.isoCode);
      setCountryIsoData(isoCode);
    }
  }, [countriesData]);

  useEffect(() => {
    if (location?.state?.firstName || location?.state?.lastName || location?.state?.phoneNumber) {
      setValue('firstName', location?.state?.firstName, { shouldTouch: true });
      setValue('lastName', location?.state?.lastName, { shouldTouch: true });
      setValue('email', location?.state?.email, { shouldTouch: true });
      setValue('phoneNumber', location?.state?.phoneNumber, { shouldTouch: true });
    }
  }, [location, setValue]);

  const loginRedirect = async (loginData) => {
    if (Object.keys(loginData).length > 0) {
      if (
        loginData?.status === 'fail' ||
        loginData?.status === 'error' ||
        loginData?.status !== 200
      ) {
        enqueueSnackbar(loginData.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      } else if (loginData?.status === 200 && loginData?.user) {
        const userData = loginData.user;
        if (userData?.roles?.type === 'business_user') {
          history.push('/select-business');
        } else if (userData?.roles?.type === 'admin') {
          history.push('/admin/dashboard');
        }
      }
    }
  };

  const onSubmit = async (data) => {
    setRefreshIcon(true);
    const countryData = Object.entries(customLabels)?.find(
      ([key, value]) => value.isoCode === selected
    )?.[1];
    try {
      const isEmail = data?.email && !location?.state?.isEmailVerified;
      const isPhoneNumber = data?.phoneNumber && !location?.state?.isPhoneNumberVerified;

      if (isPhoneNumber) {
        const checkPhoneNumberExist = await checkPhoneNumberExistOrNot(
          data.phoneNumber,
          enqueueSnackbar
        );
        if (!checkPhoneNumberExist) return;
        const sendOtpOnPhone = await sendOTPOnPhoneNumberVerification(
          data.phoneNumber,
          countryData.secondary || `+${ipBasedData?.countryCode}`,
          enqueueSnackbar
        );
        if (!sendOtpOnPhone) return;
        history.push('/verifyOtp', {
          ...location?.state,
          firstName: data?.firstName || '',
          lastName: data?.lastName || '',
          countryId: countryData?.countryId || location?.state?.countryId,
          isComeFromUserInformation: true,
          ...(location?.state?.email
            ? {
                phoneNumber: data?.phoneNumber || '',
                countryCode: countryData?.secondary,
                isoCode: countryData?.isoCode || ipBasedData?.isoCode,
              }
            : {
                email: location?.state?.email,
              }),
        });
      } else if (isEmail) {
        const checkEmailExist = await checkEmailExistOrNot(data.email, enqueueSnackbar);
        if (!checkEmailExist) return;
        const sendOtpOnEmail = await sendOTPOnEmailVerification(data.email, enqueueSnackbar);
        if (!sendOtpOnEmail) return;
        history.push('/verifyOtp', {
          ...location?.state,
          firstName: data?.firstName || '',
          lastName: data?.lastName || '',
          isComeFromUserInformation: true,
          email: data.email,
        });
      } else {
        handleSubmitData(data);
      }
    } catch (error) {
      console.error('Error during form submission:', error);
    } finally {
      setRefreshIcon(false);
    }
  };

  const handleSubmitData = async (data) => {
    const obj = getEncryptedData(
      JSON.stringify({
        isEmailRegistration: false,
        e: data?.email,
        firstName: data?.firstName,
        lastName: data?.lastName,
        phoneNumber: {
          countryCode: location?.state?.countryCode,
          phoneNumber: parseInt(location?.state?.phoneNumber, 10),
          isoCode: location?.state?.isoCode,
        },
        accessToken: '',
        userPackageIds: location?.state?.userPackageIds,
        countryId: parseInt(location?.state?.countryId, 10),
        isSocialUser: true,
        timeZone: '',
        isQuickHubProduct: true,
      })
    );
    const payload = {
      query: queryData.signupV3,
      variables: { data: obj },
    };
    const result = await getDataToServerForAuth(payload);
    setRefreshIcon(false);
    if (result?.signupV3?.status === 200) {
      enqueueSnackbar(result?.signupV3?.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      if (result?.signupV3?.refreshToken) {
        dispatch(setUserData(result?.signupV3?.user));
        userService.setSession(result?.signupV3?.accessToken);
        loginRedirect(result?.signupV3);
      }
    } else {
      enqueueSnackbar(result?.signupV3?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  return (
    <div className="flex items-center justify-center bg-white h-full w-full sm:px-0 px-16 sm:py-0 py-40">
      <img
        src="assets/images/background/top-round.png"
        className="absolute top-0 right-0 hidden lg:block"
        alt="..."
      />
      <img
        src="assets/images/background/bottom-round.png"
        className="absolute bottom-0 left-0 hidden lg:block"
        alt="..."
      />
      <div>
        <img
          src="assets/images/logo/quick_hub.png"
          className="mx-auto md:max-w-88 max-w-80"
          alt="logo"
        />
        <Typography className="mt-10 mb-32 text-24 font-bold text-center text-black">
          {t('signUpPage.createAccount')}
        </Typography>
        <Paper className="shadow-none">
          <div className="bg-grey-50 sm:max-w-[816px] md:min-w-[816px] w-full mx-auto rounded-2xl items-center justify-center sm:shadow md:shadow-none py-20 px-20 md:py-40 md:px-80">
            <div className="w-full max-w-320 sm:w-320 mx-auto">
              <Typography className="mt-10 mb-32 text-24 font-bold text-center text-black">
                Personal Information
              </Typography>
              <form
                name="registerForm"
                noValidate
                className="w-full"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="lg:flex">
                  <div>
                    <Controller
                      name="firstName"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          className={`${errors.firstName ? 'mb-10' : 'mb-24'}`}
                          label={t('signUpPage.formField.firstName.name')}
                          type="text"
                          size="small"
                          error={!!errors.firstName}
                          helperText={errors.firstName ? errors.firstName.message : ''}
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                    <Controller
                      name="lastName"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          className={`mb-${errors.lastName ? '10' : '24'}`}
                          label={t('signUpPage.formField.lastName.name')}
                          type="text"
                          error={!!errors.lastName}
                          helperText={errors.lastName ? errors.lastName.message : ''}
                          size="small"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                    <div className="flex flex-col justify-center w-full">
                      {location?.state?.email && location?.state?.isEmailVerified ? (
                        <span className="relative">
                          <span className="absolute z-20">
                            <ReactFlagsSelect
                              className="react-flag-button"
                              selected={selected}
                              onSelect={(code) => setSelected(code)}
                              countries={countryIsoData}
                              searchable
                              customLabels={customLabels}
                              showSelectedLabel={false}
                            />
                          </span>
                          <Controller
                            name="phoneNumber"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                className={`${errors.phoneNumber ? 'mb-10' : 'mb-24'} rounded-md`}
                                placeholder={t('signUpPage.formField.phoneNumber.name')}
                                type="number"
                                sx={{
                                  '& .MuiInputBase-input': {
                                    paddingLeft: '125px',
                                    border: '1px solid #666666',
                                    textIndent: 'initial',
                                    transition: 'all .2s ease-in-out',
                                    borderRadius: '5px',
                                  },
                                }}
                                size="small"
                                error={!!errors.phoneNumber}
                                helperText={errors.phoneNumber ? errors.phoneNumber.message : ''}
                                variant="outlined"
                                required
                                fullWidth
                              />
                            )}
                          />
                        </span>
                      ) : (
                        <Controller
                          name="email"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              className="mb-24"
                              label={`${t('signUpPage.formField.email.name')} (${t(
                                'signUpPage.optional'
                              )})`}
                              type="text"
                              size="small"
                              error={!!errors.email}
                              helperText={errors?.email?.message}
                              variant="outlined"
                              fullWidth
                            />
                          )}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <Button
                  variant="contained"
                  color="secondary"
                  className="w-full md:text-14 font-semibold disabled:text-black rounded-md"
                  aria-label="Sign in"
                  type="submit"
                  disabled={!isValid || refreshIcon}
                >
                  {t('signUpPage.buttons.next')}
                  {refreshIcon && (
                    <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                  )}
                </Button>
              </form>
            </div>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default UserInformation;
