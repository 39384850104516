import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Icon,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Drawer,
  Input,
  TextField,
  CircularProgress,
  Pagination,
} from '@mui/material';
import userService from 'src/app/services';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { selectDashboardData } from 'src/app/store/userSlice';
import CloseIcon from '@mui/icons-material/Close';
import history from '@history';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SendIcon from '@mui/icons-material/Send';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FuseLoading from '@fuse/core/FuseLoading';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import BrokenImageOutlinedIcon from '@mui/icons-material/BrokenImageOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import ShareIcon from '@mui/icons-material/Share';
import { useSnackbar } from 'notistack';
import SocialPlatformTab from './socialPlatformTab';
import {
  fetchSocialConnections,
  handleApiRequest,
  handleFBLogin,
  handleSocialResponse,
} from '../../common/common';
import AnalyticsDialogBox from './AnalyticsDialogBox';
import quickSocialQuery from '../../query/quickSocial';
import quickPostQuery from '../../query/quickPost';
import PostEditDialogBox from './PostEditDialogBox';
import ConformationDialogBox from '../../CommonComponent/ConformationDialogBox';
import TemplatePopUp from './TemplatePopUp';
import UserProfile from '../../common/userProfile';
import SelectPlatformBox from './SelectPlatformBox';
import SharePost from '../quick-post/SharePost';

const AllSharePost = () => {
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const dummyPosts = {
    id: 1,
    postImage: 'https://via.placeholder.com/224',
    profileImage: 'https://via.placeholder.com/48',
    username: 'Hotel_Flavor Haven',
    postDate: '10 Jun 2024 9:57 AM',
    postContent:
      "Take advantage of our Summer Sale! We're offering fantastic 30% discount on all items, from now until end of the month. Whether you're looking for dishes or Read More...",
    likes: 2,
    commentsCount: 25,
    shares: 9,
    comments: [
      {
        id: Math.random(),
        profileImage: 'https://via.placeholder.com/36',
        username: userCurrentBusinessDetails?.name,
        commentDate: new Date().setHours(new Date().getMinutes() - 4),
        commentText: "Great offer! I'll definitely visit soon. Do you have vegetarian options?",
        likes: 2,
        likeOrUnlike: true,
        replies: [
          {
            id: Math.random(),
            profileImage: 'https://via.placeholder.com/36',
            username: userCurrentBusinessDetails?.name,
            replyDate: '30 mins ago',
            replyText: 'Yes, we have an extensive vegetarian menu!',
            likes: 1,
            likeOrUnlike: true,
          },
        ],
      },
      {
        id: Math.random(),
        profileImage: 'https://via.placeholder.com/36',
        username: userCurrentBusinessDetails?.name,
        commentDate: new Date().setHours(new Date().getMinutes() - 5),
        commentText: 'Amazing discounts! Is the offer available online too?',
        likes: 0,
        likeOrUnlike: false,
        replies: [],
      },
    ],
  };
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [postDetails, setPostDetails] = useState([]);
  const [selectedPost, setSelectedPost] = useState({});
  const [selectedIndex, setSelectedIndex] = useState();
  const [showOptions, setShowOptions] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedPostDrawer, setSelectedPostDrawer] = useState(null);
  const [expandedPosts, setExpandedPosts] = useState({});
  const [socialData, setSocialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showEditPost, setShowEditPost] = useState(false);
  const [showDeletePost, setShowDeletePost] = useState(false);
  const [openTemplatePopUp, setOpenTemplatePopUp] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isExpandedDrawer, setIsExpandedDrawer] = useState(false);
  const loginUserData = userService.getUserData();
  const [facebookUserId, setFacebookUserId] = useState();
  const [connectionData, setConnectionData] = useState([]);
  const [accessToken, setAccessToken] = useState();
  const [commentText, setCommentText] = useState();
  const [replyCommentText, setReplyCommentText] = useState();
  const [commentsData, setCommentsData] = useState(dummyPosts);
  const [selectedPage, setSelectedPage] = useState([]);
  const [socialPlatformId, setSocialPlatformId] = useState(null);
  const [isReply, setIsReply] = useState(false);
  const [selectUserToReply, setSelectUserToReply] = useState('');
  const [openDialog, setOpenDialog] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const appKey = process.env.REACT_APP_WABA_APP_ID;
  const fbConfigKey = process.env.REACT_APP_FB_CONFIG_ID;
  const inConfigKey = process.env.REACT_APP_IN_CONFIG_ID;

  const fetchAgentSupportStatus = useCallback(async () => {
    if (userCurrentBusinessDetails?.id) {
      setIsLoading(true);
      const queryMap = {
        9: quickSocialQuery.getAllFacebookChannelData,
        10: quickSocialQuery.getInstagramPosts,
        1: quickPostQuery.getViewedPosts,
      };
      const query = queryMap[activeStep];
      if (query) {
        const payload = {
          query,
          variables: {
            userBusinessId: Number(userCurrentBusinessDetails?.id),
            pageNumber: page,
            pageSize: 10,
          },
        };
        try {
          const res = await handleApiRequest(payload);
          setIsLoading(false);
          const responseData =
            activeStep === 9
              ? res?.getAllFacebookChannelData
              : activeStep === 10
              ? res?.getInstagramPosts
              : activeStep === 1
              ? res?.getViewedPosts
              : null;
          if (responseData?.status === 200) {
            setPostDetails(responseData?.data ?? []);
            setTotalCount(responseData?.totalCount);
          } else {
            setPostDetails([]);
            console.error('Error fetching posts:', responseData?.message);
          }
        } catch (error) {
          console.error('API call failed:', error);
        }
      }
    }
  }, [activeStep, userCurrentBusinessDetails?.id, page]);

  useEffect(() => {
    fetchAgentSupportStatus();
  }, [fetchAgentSupportStatus]);

  useEffect(() => {
    setPage(1);
  }, [activeStep]);

  const handleMenuClick = (event, menuId, data, index) => {
    setAnchorEl(event.currentTarget);
    setSelectedMenu(menuId);
    setSelectedPost(data);
    setSelectedIndex(index);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedMenu(null);
  };

  const onDeletePost = () => {
    setShowDeletePost(false);
    setPostDetails(
      postDetails.filter((post) => post?.id !== selectedPost?.id) // Remove the object where `post?.id === data?.id`
    );
    enqueueSnackbar('Post deleted successfully.', {
      variant: 'success',
      autoHideDuration: 2000,
    });
  };
  const handleCloseEditPost = (data) => {
    setPostDetails(
      postDetails.map((post) => {
        if (post?.id === data?.id) {
          return { ...post, ...data };
        }
        return post;
      })
    );
    setShowEditPost(false);
  };

  const toggleExpandPost = (postId) => {
    setExpandedPosts((prevExpanded) => ({
      ...prevExpanded,
      [postId]: !prevExpanded[postId],
    }));
  };

  const handleSocialConnection = async (social) => {
    setLoading(true);
    try {
      let authResponse;
      if (social === 9 || social === 10) {
        authResponse = await handleFBLogin(appKey, fbConfigKey, inConfigKey, social);
        if (authResponse) {
          const fbAccessToken = authResponse?.accessToken;
          setFacebookUserId(authResponse?.userID);

          if (fbAccessToken && userCurrentBusinessDetails?.id) {
            await fetchSocialConnections(
              social,
              userCurrentBusinessDetails,
              setConnectionData,
              setAccessToken,
              setOpenDialog,
              setSelectedPage,
              setLoading,
              fbAccessToken,
              enqueueSnackbar
            );
          } else {
            console.log('Missing Facebook access token or business details.');
          }
        } else {
          console.log('Facebook login failed or was cancelled.');
        }
      } else if (social?.reviewPlatformId === 11) {
        // handleXLogin();
      } else if (social?.reviewPlatformId === 12) {
        // handleLinkedInLogin();
      }
    } catch (error) {
      console.error('Error during social connection:', error);
    } finally {
      setLoading(false);
    }

    setSocialPlatformId(social);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleWritePost = () => {
    history.push('/quick-social/post');
  };

  const handleTemplatePost = () => {
    setOpenTemplatePopUp(true);
  };

  const handleClosePopup = () => {
    setOpenDrawer(false);
  };

  const handleCloseTemplatePopUp = () => {
    setOpenTemplatePopUp(false);
  };

  const handleShare = () => {
    setOpenShare(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenShare(false);
  };

  const formatTimeFromDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'short' });
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${day} ${month} ${year} ${hours}:${formattedMinutes} ${ampm}`;
  };

  const hasUnconnectedPlatforms = socialData?.some(
    (item) => !item?.isConnected && item?.reviewPlatformId === activeStep
  );

  const handleSubmitComment = () => {
    setCommentsData({
      ...commentsData,
      comments: [
        ...commentsData.comments,
        {
          id: commentsData.comments.length + 1,
          profileImage: 'https://via.placeholder.com/36',
          username: userCurrentBusinessDetails?.name,
          commentDate: new Date(),
          commentText,
          likes: 0,
          likeOrUnlike: false,
          replies: [],
        },
      ],
    });
    setCommentText('');
  };

  const handleSubmitReplyComment = (commentData) => {
    setCommentsData((prevCommentsData) => {
      const updatedComments = prevCommentsData.comments.map((comment) => {
        if (comment.id === commentData.id) {
          return {
            ...comment,
            replies: [
              ...comment.replies,
              {
                id: Math.random(),
                profileImage: 'https://via.placeholder.com/36',
                username: userCurrentBusinessDetails?.name,
                replyDate: new Date(),
                replyText: replyCommentText,
                likeOrUnlike: false,
                likes: 0,
              },
            ],
          };
        }
        return comment;
      });

      return {
        ...prevCommentsData,
        comments: updatedComments,
      };
    });
    setIsReply(false);
    setReplyCommentText('');
  };

  const deleteComment = (id) => {
    setRefreshIcon(true);
    setSelectUserToReply(id);
    setTimeout(() => {
      const filterComment = commentsData.comments?.filter((item) => item.id !== id);
      setCommentsData({ ...commentsData, comments: filterComment });
      setRefreshIcon(false);
      enqueueSnackbar('Comment deleted successfully.', {
        variant: 'success',
        autoHideDuration: 2000,
      });
    }, 2000);
  };

  const deleteReplyComment = (commentId, replyId) => {
    setRefreshIcon(true);
    setSelectUserToReply(replyId);
    setTimeout(() => {
      const updatedComments = commentsData.comments.map((comment) => {
        if (comment.id === commentId) {
          return {
            ...comment,
            replies: comment.replies.filter((reply) => reply.id !== replyId),
          };
        }
        return comment;
      });
      setCommentsData({ ...commentsData, comments: updatedComments });
      setRefreshIcon(false);
      enqueueSnackbar('Comment deleted successfully.', {
        variant: 'success',
        autoHideDuration: 2000,
      });
    }, 2000);
  };

  const commentReplyLikeOrUnlike = (commentId, replyId) => {
    setCommentsData((prevCommentsData) => {
      const updatedComments = prevCommentsData.comments.map((comment) => {
        if (comment.id === commentId) {
          return {
            ...comment,
            replies: comment.replies.map((reply) => {
              if (reply.id === replyId) {
                return {
                  ...reply,
                  likes: reply.likeOrUnlike ? reply.likes - 1 : reply.likes + 1,
                  likeOrUnlike: !reply.likeOrUnlike,
                };
              }
              return reply;
            }),
          };
        }
        return comment;
      });

      return {
        ...prevCommentsData,
        comments: updatedComments,
      };
    });
  };

  const commentLikeOrUnlike = (commentId) => {
    setCommentsData((prevCommentsData) => {
      const updatedComments = prevCommentsData.comments.map((comment) => {
        if (comment.id === commentId) {
          return {
            ...comment,
            likes: comment.likeOrUnlike ? comment.likes - 1 : comment.likes + 1,
            likeOrUnlike: !comment.likeOrUnlike,
          };
        }
        return comment;
      });

      return {
        ...prevCommentsData,
        comments: updatedComments,
      };
    });
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="p-20 lg:p-32">
      <div className="sm:flex w-full items-center justify-between mb-24">
        <Typography
          component={motion?.span}
          initial={{ x: -20 }}
          animate={{ x: 0, transition: { delay: 0.2 } }}
          className="sm:flex text-24 md:text-28 font-bold"
        >
          {t('quickSocial.posts')}
        </Typography>
        <Button
          className="text-16 px-14 rounded-md"
          variant="contained"
          color="quickSocial"
          onClick={(event) => handleMenuClick(event, 'createPostMenu')}
        >
          <span className="text-20 sm:text-28 mr-10">+</span>
          {t('createPost.title')}
        </Button>
        <Menu
          anchorEl={selectedMenu === 'createPostMenu' ? anchorEl : null}
          open={Boolean(anchorEl) && selectedMenu === 'createPostMenu'}
          onClose={handleCloseMenu}
          classes={{ paper: 'min-w-112' }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            '& .MuiPaper-root': {
              boxShadow: '1px 1px 3px gray',
            },
          }}
        >
          <MenuItem onClick={() => handleTemplatePost()}>
            <BrokenImageOutlinedIcon className="text-quick-social text-20 mr-8" />
            <p className="text-16 font-500">{t('quickSocial.selectTemplate')}</p>
          </MenuItem>
          <MenuItem onClick={() => handleWritePost()}>
            <CreateOutlinedIcon className="border-1 border-quick-social p-1 rounded-6 text-quick-social text-20 mr-8" />
            <p className="text-16 font-500">{t('quickSocial.writeYourOwn')}</p>
          </MenuItem>
        </Menu>
      </div>
      <SocialPlatformTab
        onSelectTab={setActiveStep}
        socialData={socialData}
        setSocialData={setSocialData}
      />

      {!isLoading ? (
        <div>
          {postDetails?.length > 0 ? (
            <>
              {postDetails?.map((post, i) => {
                const isExpanded = expandedPosts[post?.id] || false;
                const text =
                  activeStep === 10 ? post?.caption : activeStep === 9 ? post?.description : '';
                return (
                  <div
                    className={
                      activeStep !== 1
                        ? 'bg-white rounded-md mb-16 p-16 sm:max-w-4xl sm:flex gap-16'
                        : 'bg-white max-w-288 py-10 justify-center items-center flex flex-col mb-10'
                    }
                    key={i}
                    aria-hidden
                  >
                    {activeStep === 1 && (
                      <div className="flex gap-20 mb-20 ">
                        {loginUserData?.profileURL ? (
                          <img
                            src={loginUserData?.profileURL}
                            className="w-44 h-44 rounded-full inline-block"
                            alt="User Profile"
                          />
                        ) : (
                          <UserProfile name={loginUserData?.fullName} width="45px" height="45px" />
                        )}
                        <div>
                          <span className="block font-medium text-20 mb-4">
                            {post?.userName || post?.pageName}
                          </span>
                          <span className="text-16 font-medium">
                            {formatTimeFromDate(post?.cts)}
                          </span>
                        </div>
                      </div>
                    )}
                    {post?.media?.image ||
                    post?.mediaUrl ||
                    post?.share_post_template?.templateImage ? (
                      <img
                        src={
                          activeStep === 9
                            ? post?.media?.image
                            : activeStep === 10
                            ? post?.mediaUrl
                            : activeStep === 1
                            ? post?.share_post_template?.templateImage
                            : undefined
                        }
                        className="w-224 h-224 rounded-md inline-block sm:mb-0 mb-10"
                        alt="Post"
                      />
                    ) : null}
                    <div className="w-full flex flex-col justify-between">
                      <div className="flex flex-col gap-20">
                        <div className="flex justify-between">
                          {activeStep !== 1 && (
                            <div className="flex gap-20">
                              <img
                                src={
                                  activeStep === 9
                                    ? post?.profilePictureUrl
                                    : activeStep === 10
                                    ? post?.userProfile
                                    : ''
                                }
                                className="w-60 h-60 rounded-full inline-block"
                                alt="User Profile"
                              />
                              <div>
                                <span className="block font-medium text-20 mb-4">
                                  {post?.userName || post?.pageName}
                                </span>
                                <span className="text-16 font-medium">
                                  {formatTimeFromDate(
                                    activeStep === 9
                                      ? post?.createdTime
                                      : activeStep === 10
                                      ? post?.createTime
                                      : null
                                  )}
                                </span>
                              </div>
                            </div>
                          )}
                          {activeStep !== 1 && (
                            <>
                              <IconButton
                                className="hover:bg-transparent !p-0 sm:!p-10"
                                onClick={(event) => handleMenuClick(event, 'postMenu', post, i)}
                              >
                                <MoreVertIcon />
                              </IconButton>

                              <Menu
                                anchorEl={
                                  selectedMenu === 'postMenu' && selectedIndex === i
                                    ? anchorEl
                                    : null
                                }
                                open={
                                  Boolean(anchorEl) &&
                                  selectedMenu === 'postMenu' &&
                                  selectedIndex === i
                                }
                                onClose={handleCloseMenu}
                                classes={{ paper: 'min-w-112 shadow-none' }}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'right',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right',
                                }}
                                sx={{
                                  '& .MuiPaper-root': {
                                    boxShadow: '1px 1px 3px gray',
                                  },
                                }}
                              >
                                {/* <MenuItem
                                  onClick={() => {
                                    setShowEditPost(true);
                                  }}
                                >
                                  <Icon className="text-20 mr-8 ">edit</Icon>
                                  <spna className="text-16 font-500">Edit</spna>
                                </MenuItem> */}
                                {/* <MenuItem
                                  onClick={() => {
                                    setShowDeletePost(true);
                                  }}
                                >
                                  <Icon className="text-20 mr-8 ">delete</Icon>
                                  <spna className="text-16 font-500">Delete</spna>
                                </MenuItem> */}
                                <MenuItem onClick={() => handleShare()}>
                                  <ShareIcon className="text-20 mr-8 " />
                                  <p className="text-16 font-500">{t('createPost.shareBtn')}</p>
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    setShowOptions(true);
                                    handleCloseMenu();
                                  }}
                                >
                                  <AnalyticsOutlinedIcon className="text-20 mr-8 " />
                                  <p className="text-16 font-500">
                                    {t('quickSocial.postAnalytics')}
                                  </p>
                                </MenuItem>
                              </Menu>
                            </>
                          )}
                        </div>
                        <div className="text-16 font-500 mb-4">
                          <p
                            className={`${
                              isExpanded ? 'whitespace-normal text-justify' : 'line-clamp-3'
                            } text-justify`}
                          >
                            {text}
                          </p>
                          {text && text?.length > 120 && (
                            <Button
                              className="text-blue-500 mt-2 hover:bg-transparent"
                              onClick={() => toggleExpandPost(post?.id)}
                            >
                              {isExpanded ? 'Show Less' : 'Read More'}
                            </Button>
                          )}
                        </div>
                      </div>
                      {activeStep !== 1 && (
                        <div
                          className="flex gap-28 mt-auto"
                          aria-hidden
                          // onClick={() => {
                          //   setOpenDrawer(true);
                          //   setSelectedPostDrawer(post);
                          // }}
                        >
                          <span className="bg-gray-A500 rounded-xl w-96 h-28 flex items-center text-center text-12 font-medium">
                            <span className="mx-auto inline-block">
                              <FavoriteBorderIcon className="text-18 mr-6" />
                              {activeStep === 9
                                ? Number(post?.id) === 442801
                                  ? commentsData.likes
                                  : post?.activity?.likes
                                : Number(post?.id) === 1409049
                                ? commentsData.likes
                                : post?.activity?.like_count}
                            </span>
                          </span>
                          <span className="bg-gray-A500 rounded-xl w-96 h-28 flex items-center text-center text-12 font-medium">
                            <span className="mx-auto inline-block">
                              <ChatBubbleOutlineIcon className="text-18 mr-6" />
                              {activeStep === 9
                                ? Number(post?.id) === 442801
                                  ? commentsData.comments.length
                                  : post?.activity?.comments
                                : Number(post?.id) === 1409049
                                ? commentsData.comments.length
                                : post?.activity?.comments_count}
                            </span>
                          </span>
                          <span className="bg-gray-A500 rounded-xl w-96 h-28 flex items-center text-center text-12 font-medium">
                            <span className="mx-auto inline-block">
                              <SendIcon className="text-18 mr-6" />
                              {activeStep === 9
                                ? Number(post?.id) === 442801
                                  ? commentsData.shares
                                  : post?.activity?.shares
                                : Number(post?.id) === 1409049
                                ? commentsData.shares
                                : post?.activity?.impression}
                            </span>
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
              <div className="bg-white px-52 py-32 bottom-0 right-0 w-full flex justify-end">
                <Pagination
                  count={Math.ceil(totalCount / 10)}
                  page={page}
                  onChange={handlePageChange}
                  showFirstButton
                  showLastButton
                />
              </div>
            </>
          ) : (
            <div>
              {hasUnconnectedPlatforms ? (
                <div className="flex w-full justify-center items-center ">
                  <div className="flex flex-col items-center justify-center text-center mt-112 w-400">
                    <img
                      src="assets/images/icon/socialNotConnectIcon.png"
                      alt="connect"
                      className="mb-16"
                    />
                    <h1 className="text-24 font-bold mb-16">
                      {t('quickSocial.connect')}{' '}
                      {(() => {
                        const name = socialData?.find(
                          (item) => !item?.isConnected && item?.reviewPlatformId === activeStep
                        )?.name;
                        return name ? name.charAt(0).toUpperCase() + name.slice(1) : '';
                      })()}{' '}
                      {t('quickSocial.account')}
                    </h1>
                    <p className="text-gray-600 font-medium mb-16">
                      Your{' '}
                      {socialData
                        ?.find(
                          (item) => !item?.isConnected && item?.reviewPlatformId === activeStep
                        )
                        ?.name?.replace(/^./, (char) => char.toUpperCase()) || ''}{' '}
                      {t('quickSocial.notConnectContent')}
                    </p>
                    <Button
                      variant="contained"
                      color="quickSocial"
                      className="rounded-md text-medium text-16 px-80"
                      onClick={() => handleSocialConnection(activeStep)}
                    >
                      {t('quickSocial.connectNow')}
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="flex w-full justify-center items-center">
                  <div className="flex flex-col items-center justify-center text-center mt-112">
                    <img
                      src="assets/images/icon/socialNoPost.png"
                      alt="connect"
                      className="mb-16"
                    />
                    <h1 className="text-24 font-bold mb-16"> {t('quickSocial.noPost')} </h1>
                    <p className="text-gray-600 font-medium mb-16">
                      {t('quickSocial.noPostContent')}
                    </p>
                    <Button
                      variant="contained"
                      color="quickSocial"
                      className="rounded-md text-medium text-20 px-80"
                      onClick={() => handleTemplatePost()}
                    >
                      {t('quickSocialScheduled.createPost')}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div className="w-full h-512 justify-center items-center flex">
          <FuseLoading />
        </div>
      )}

      {openTemplatePopUp && (
        <TemplatePopUp open={openTemplatePopUp} onClose={handleCloseTemplatePopUp} />
      )}

      {openShare && (
        <SharePost
          open={openShare}
          onClose={handleCloseDeleteDialog}
          url={selectedPost?.media?.permalink_url || selectedPost?.mediaUrl}
          image={selectedPost?.media?.permalink_url || selectedPost?.postUrl}
        />
      )}

      {showOptions && selectedPost && (
        <AnalyticsDialogBox
          open={showOptions}
          onClose={() => setShowOptions(false)}
          post={selectedPost}
          activeStep={activeStep}
        />
      )}
      {showEditPost && selectedPost && (
        <PostEditDialogBox
          open={showEditPost}
          onClose={(data) => {
            handleCloseEditPost(data);
          }}
          post={selectedPost}
          activeStep={activeStep}
        />
      )}
      {showDeletePost && (
        <ConformationDialogBox
          open={showDeletePost}
          onDelete={() => onDeletePost()}
          onClose={() => setShowDeletePost(false)}
          confirmationText="Are You Sure?"
          deleteImage="/assets/images/icon/DeleteClient.svg"
          backgroundColor="red"
          textColor="red"
          buttonText={t('manageBusiness.button.delete')}
        />
      )}
      <Drawer anchor="right" open={openDrawer} onClose={handleClosePopup} className="">
        <div className="flex justify-between p-20 bg-white">
          <h2 className="text-20 font-bold mb-4 text-center justify-center items-center flex">
            Post Details
          </h2>
          <CloseIcon onClick={handleClosePopup} className="cursor-pointer" />
        </div>
        <div className=" bg-gray-A500  w-full sm:min-w-600 h-full rounded-md p-16">
          <div className="bg-white rounded-md p-16 border-gray-200 border border-solid">
            <div className="flex items-top">
              <img
                src={
                  activeStep === 9
                    ? selectedPostDrawer?.media?.image
                    : activeStep === 10
                    ? selectedPostDrawer?.mediaUrl
                    : activeStep === 1
                    ? selectedPostDrawer?.share_post_template?.templateImage
                    : undefined
                }
                alt="..."
                width="224"
                height="224"
                className="mr-20 max-h-224"
              />
              <div>
                <div className="flex items-center">
                  <img
                    src={
                      activeStep === 9
                        ? selectedPostDrawer?.profilePictureUrl
                        : activeStep === 10
                        ? selectedPostDrawer?.userProfile
                        : ''
                    }
                    alt="..."
                    width="48"
                    height="48"
                    className="mr-12 rounded-full"
                  />
                  <div>
                    <span className="text-20 font-medium block">
                      {selectedPostDrawer?.userName || selectedPostDrawer?.pageName}
                    </span>
                    <span className="text-16 block">
                      {formatTimeFromDate(
                        activeStep === 9
                          ? selectedPostDrawer?.createdTime
                          : activeStep === 10
                          ? selectedPostDrawer?.createTime
                          : null
                      )}
                    </span>
                  </div>
                </div>
                <Typography className="text-16 py-16 font-semibold max-w-320 text-grey-600">
                  <Typography className="text-16 py-16 font-semibold max-w-320 text-grey-600">
                    {selectedPostDrawer?.description?.length > 120 && !isExpandedDrawer
                      ? `${selectedPostDrawer?.description.slice(0, 120)}...`
                      : selectedPostDrawer?.description}

                    {selectedPostDrawer?.description?.length > 120 && (
                      <Button
                        onClick={() => setIsExpandedDrawer((prev) => !prev)}
                        className="text-quick-social p-0  min-w-auto min-h-auto font-semibold ml-4 inline-block hover:bg-transparent"
                      >
                        {isExpandedDrawer ? 'Read Less' : 'Read More'}
                      </Button>
                    )}
                  </Typography>
                </Typography>
                <div className="flex gap-28 mt-auto">
                  <span className="bg-gray-A500 rounded-xl w-96 h-28 flex items-center text-center text-12 font-medium">
                    <span className="mx-auto inline-block">
                      <FavoriteBorderIcon className="text-18 mr-6" /> {commentsData.likes}
                    </span>
                  </span>
                  <span className="bg-gray-A500 rounded-xl w-96 h-28 flex items-center text-center text-12 font-medium">
                    <span className="mx-auto inline-block">
                      <ChatBubbleOutlineIcon className="text-18 mr-6" />{' '}
                      {commentsData.comments.length}
                    </span>
                  </span>
                  <span className="bg-gray-A500 rounded-xl w-96 h-28 flex items-center text-center text-12 font-medium">
                    <span className="mx-auto inline-block">
                      <SendIcon className="text-18 mr-6" /> {commentsData.shares}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div className="p-8 border border-gray-300 rounded-lg mt-36">
              <textarea
                className="w-full border-none rounded-lg p-3 text-sm resize-none"
                rows="3"
                placeholder="Add Comment"
                value={commentText}
                onChange={(e) => setCommentText(e.target.value)}
              />
              <div className="flex items-center justify-between mt-2">
                <div className="flex space-x-2">
                  <IconButton className="text-gray-500 hover:text-gray-700">
                    <EmojiEmotionsOutlinedIcon />
                  </IconButton>
                  <IconButton className="text-gray-500 hover:text-gray-700">
                    <InsertPhotoOutlinedIcon />
                  </IconButton>
                </div>
                <Button
                  variant="contained"
                  color="quickSocial"
                  className="text-sm rounded-lg"
                  onClick={() => handleSubmitComment()}
                >
                  Submit
                </Button>
              </div>
            </div>
            <hr className="my-16" />
            <div className="bg-gray-A500 p-16 rounded-md">
              <div className="mb-10">
                <span className="font-semibold text-18 inline-block">Comment</span>
                <span className="text-14 font-medium border border-solid border-grey-700 rounded-full px-12 py-2 ml-20 inline-block">
                  {commentsData?.comments?.length}
                </span>
              </div>
              {commentsData?.comments &&
                commentsData?.comments?.length > 0 &&
                commentsData?.comments
                  ?.sort((a, b) => b.id - a.id)
                  .map((comment, i) => (
                    <div
                      key={i}
                      className="bg-white rounded-md p-16 border-gray-200 border border-solid mt-16"
                    >
                      <div className="flex items-top">
                        <div className="h-36">
                          <img
                            src={comment?.profileImage}
                            alt="..."
                            width="36"
                            height="36"
                            className="mr-8 rounded-full h-36"
                          />
                        </div>
                        <div>
                          <div className="block">
                            <span className="text-16 font-semibold inline-block items-center">
                              {comment?.username}
                            </span>
                            <span className="text-14 ml-10 inline-block items-center text-grey-500">
                              {moment(comment?.commentDate).fromNow()}
                            </span>
                          </div>

                          <Typography className="text-14 py-16 font-medium max-w-480 text-grey-600 block">
                            {comment?.commentText}
                          </Typography>
                          <div className="flex gap-28 mt-auto">
                            <span className="bg-gray-A500 rounded-xl w-96 h-28 flex items-center text-center text-12 font-medium">
                              <span className="mx-auto inline-block">
                                <Button
                                  className="hover:bg-transparent text-12 font-medium"
                                  onClick={() => commentLikeOrUnlike(comment.id)}
                                >
                                  {!comment.likeOrUnlike && comment?.likes === 0 ? (
                                    <FavoriteBorderIcon className="text-18 mr-6" />
                                  ) : (
                                    <FavoriteIcon className="text-18 mr-6 text-red" />
                                  )}
                                  {comment?.likes}
                                </Button>
                              </span>
                            </span>
                            <span className="bg-gray-A500 rounded-xl w-96 h-28 flex items-center text-center">
                              <span className="mx-auto inline-block">
                                <Button
                                  className="hover:bg-transparent text-12 font-medium"
                                  onClick={() => {
                                    setIsReply(true);
                                    setSelectUserToReply(comment.id);
                                  }}
                                >
                                  <ChatBubbleOutlineIcon className="text-18 mr-6" /> Reply
                                </Button>
                              </span>
                            </span>
                            <span className="bg-gray-A500 rounded-xl w-96 h-28 flex items-center text-center">
                              <span className="mx-auto inline-block">
                                <Button
                                  className="hover:bg-transparent text-12 font-medium"
                                  onClick={() => deleteComment(comment.id)}
                                  disabled={refreshIcon && selectUserToReply === comment?.id}
                                >
                                  <DeleteOutlineIcon className="text-18 mr-6" /> Delete
                                  {refreshIcon && selectUserToReply === comment?.id && (
                                    <CircularProgress
                                      size={24}
                                      className="text-quick-social absolute mx-auto"
                                    />
                                  )}
                                </Button>
                              </span>
                            </span>
                          </div>
                          {isReply && selectUserToReply === comment.id && (
                            <div className="flex items-center gap-8 mt-8">
                              <TextField
                                className="w-full"
                                size="small"
                                placeholder="comment your reply here"
                                value={replyCommentText}
                                onChange={(e) => setReplyCommentText(e.target.value)}
                              />
                              <Button
                                variant="contained"
                                color="quickSocial"
                                className="text-sm rounded-lg"
                                onClick={() => handleSubmitReplyComment(comment)}
                              >
                                Submit
                              </Button>
                            </div>
                          )}
                          {comment?.replies &&
                            comment?.replies?.length > 0 &&
                            comment?.replies?.map((reply, index) => (
                              <div key={index} className="mt-16">
                                <div className="ml-24">
                                  <div className="flex items-center">
                                    <img
                                      src={reply?.profileImage}
                                      alt="..."
                                      width="36"
                                      height="36"
                                      className="mr-8 rounded-full"
                                    />
                                    <div className="block">
                                      <span className="text-16 font-semibold inline-block items-center">
                                        {reply?.username}
                                      </span>
                                      <span className="text-14 ml-10 inline-block items-center text-grey-500">
                                        {moment(reply?.commentDate).fromNow()}
                                      </span>
                                    </div>
                                  </div>
                                  <Typography className="text-14 py-16 font-medium max-w-480 text-grey-600 block">
                                    {reply?.replyText}
                                  </Typography>
                                </div>
                                <div className="flex gap-28 mt-auto">
                                  <span className="bg-gray-A500 rounded-xl w-96 h-28 flex items-center text-center text-12 font-medium">
                                    <span className="mx-auto inline-block">
                                      <Button
                                        className="hover:bg-transparent text-12 font-medium"
                                        onClick={() =>
                                          commentReplyLikeOrUnlike(comment?.id, reply.id)
                                        }
                                      >
                                        {!reply.likeOrUnlike && reply?.likes === 0 ? (
                                          <FavoriteBorderIcon className="text-18 mr-6" />
                                        ) : (
                                          <FavoriteIcon className="text-18 mr-6 text-red" />
                                        )}
                                        {reply?.likes}
                                      </Button>
                                    </span>
                                  </span>
                                  <span className="bg-gray-A500 rounded-xl w-96 h-28 flex items-center text-center text-12 font-medium">
                                    <span className="mx-auto inline-block">
                                      <ChatBubbleOutlineIcon className="text-18 mr-6" />
                                      Reply
                                    </span>
                                  </span>
                                  <span className="bg-gray-A500 rounded-xl w-96 h-28 flex items-center text-center">
                                    <span className="mx-auto inline-block">
                                      <Button
                                        className="hover:bg-transparent text-12 font-medium"
                                        onClick={() => deleteReplyComment(comment?.id, reply.id)}
                                        disabled={refreshIcon && selectUserToReply === reply?.id}
                                      >
                                        <DeleteOutlineIcon className="text-18 mr-6" /> Delete
                                        {refreshIcon && selectUserToReply === reply?.id && (
                                          <CircularProgress
                                            size={24}
                                            className="text-quick-social absolute mx-auto"
                                          />
                                        )}
                                      </Button>
                                    </span>
                                  </span>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </Drawer>
      {connectionData?.length > 0 && openDialog && (
        <SelectPlatformBox
          onClose={handleClose}
          openDialog={openDialog}
          socialPlatformId={socialPlatformId}
          connectionData={connectionData}
          setSelectedPage={setSelectedPage}
          selectedPage={selectedPage}
          setOpenDialog={setOpenDialog}
          handleSocialResponse={() =>
            handleSocialResponse(
              socialPlatformId,
              accessToken,
              facebookUserId,
              selectedPage,
              userCurrentBusinessDetails,
              setLoading,
              enqueueSnackbar,
              setConnectionData,
              setOpenDialog,
              fetchAgentSupportStatus
            )
          }
          handleClose={handleClose}
        />
      )}
    </div>
  );
};

export default AllSharePost;
