import { Button, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { selectDashboardData } from 'app/store/userSlice';
import { useSnackbar } from 'notistack';
import { t } from 'i18next';
import history from '@history';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ConformationDialogBox from 'src/app/main/CommonComponent/ConformationDialogBox';
import moment from 'moment';
import { useSelector } from 'react-redux';
import businessData from '../../../query/business';
import { handleApiRequest } from '../../../common/common';
import manageBusinessData from '../../../query/manageBusiness';

const CreateAndRecentCampaigns = ({ loading }) => {
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [openDialogueForDelete, setOpenDialogueForDelete] = useState(false);
  const [recentCampaignData, setRecentCampaignData] = useState([]);
  const [scheduledCampaignData, setScheduledCampaignData] = useState([]);

  const getScheduledData = useCallback(async () => {
    try {
      const payloadDataWithMessageType = {
        query: businessData.getSendCampaignMessages,
        variables: {
          userBusinessId: Number(userCurrentBusinessDetails?.id),
          isCallFromHomePage: true,
          messageType: 'scheduled',
        },
      };

      const payloadDataWithoutMessageType = {
        query: businessData.getSendCampaignMessages,
        variables: {
          userBusinessId: Number(userCurrentBusinessDetails?.id),
          isCallFromHomePage: true,
        },
      };

      const [resultWithMessageType, resultWithoutMessageType] = await Promise.all([
        handleApiRequest(payloadDataWithMessageType),
        handleApiRequest(payloadDataWithoutMessageType),
      ]);
      if (resultWithMessageType?.getSendCampaignMessages?.status === 200) {
        setScheduledCampaignData(resultWithMessageType?.getSendCampaignMessages?.data);
      }

      if (resultWithoutMessageType?.getSendCampaignMessages?.status === 200) {
        setRecentCampaignData(resultWithoutMessageType?.getSendCampaignMessages?.data);
      }
    } catch (error) {
      console.error('Error fetching scheduled data:', error);
    }
  }, [userCurrentBusinessDetails?.id]);

  useEffect(() => {
    if (userCurrentBusinessDetails?.id && !loading) {
      getScheduledData();
    }
  }, [getScheduledData, loading, userCurrentBusinessDetails?.id]);

  const deleteScheduledCampaign = async () => {
    try {
      const payload = {
        query: manageBusinessData.deleteDraftAndScheduleCamping,
        variables: {
          campaignServicesId: Number(selectedContactId),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.deleteDraftAndScheduleCamping?.status === 200) {
        enqueueSnackbar(result?.deleteDraftAndScheduleCamping?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
        setOpenDialogueForDelete(false);
        getScheduledData();
      }
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  };

  const handleCloseDeleteDialog = () => setOpenDialogueForDelete(false);

  return (
    <>
      {scheduledCampaignData?.length > 0 && (
        <div>
          <p className="text-16 font-600">{t('quickCampaigns.scheduledCampaign')}</p>
          {scheduledCampaignData?.map((item, index) => (
            <div className="flex flex-col gap-14 bg-white rounded-8 p-10 mt-10 sm:p-16" key={index}>
              <div className="flex justify-between items-center">
                <div className="flex gap-10 flex-row items-center">
                  <p className="text-14 sm:text-16 bg-opacity-30 font-600 text-black">
                    {item?.campaignTitle}
                  </p>
                </div>
                <div className="flex gap-10">
                  <Button
                    className="!min-w-0 !min-h-28 !max-h-32 rounded-full bg-indigo-50 "
                    onClick={() => history.push('quick-campaign/createCampaign', item)}
                  >
                    <ModeEditIcon className="text-16 sm:text-20 text-quick-campaign" />
                  </Button>
                  <Button
                    className="!min-w-0 bg-indigo-50 !min-h-28 !max-h-32 rounded-full"
                    onClick={() => {
                      setOpenDialogueForDelete(true);
                      setSelectedContactId(item?.id);
                    }}
                  >
                    <DeleteOutlineIcon className="text-16 sm:text-20 text-quick-campaign" />
                  </Button>
                </div>
              </div>
              <div className="bg-indigo-50 rounded-4 p-10 bg-opacity-30">
                <p>{userCurrentBusinessDetails?.name}</p>
                <div className="bg-white rounded-8 p-10 mt-10">
                  {item?.campaign_platform?.id !== 2 &&
                  item?.campaign_services_standard_messages?.htmlTemplate ? (
                    <Typography
                      className="mt-8 w-full"
                      dangerouslySetInnerHTML={{
                        __html: item?.campaign_services_standard_messages?.htmlTemplate,
                      }}
                    />
                  ) : (
                    <p>{item?.campaign_services_standard_messages?.messages}</p>
                  )}
                </div>
                <h1 className="text-16 font-600 my-10">{t('quickCampaigns.channel')}</h1>
                <div className="bg-white rounded-8 p-10 mt-10 font-500">
                  <p>{item?.campaign_services_standard_messages?.campaignPlatform}</p>
                </div>
              </div>
              <div className="flex items-center w-full rounded-md ">
                <div className="w-full sm:w-2/3 flex items-center gap-10">
                  <img
                    src="/assets/images/icon/campaignsTime.svg"
                    alt="Preview"
                    className="sm:w-32 sm:h-32 w-24 h-24 object-cover rounded"
                  />
                  <p className="text-12 sm:text-14 font-500">
                    {moment(Number(item?.cts)).format('MMMM DD, YYYY h:mmA')}
                  </p>
                </div>
                <div className="w-1/3 flex items-center justify-end gap-6">
                  <PersonOutlineOutlinedIcon className="sm:w-32 sm:h-32 w-24 h-24 text-quick-campaign" />
                  <p>{item?.totalCustomers}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {recentCampaignData?.length > 0 && (
        <div>
          <p className="text-16 font-600">{t('quickCampaigns.recentCampaign')}</p>
          {recentCampaignData?.map((item, index) => (
            <div className="flex flex-col gap-20 bg-white rounded-8 p-10 mt-10 sm:p-16" key={index}>
              <div className="flex justify-between items-center">
                <div className="flex gap-10 flex-row justify-between w-full items-center">
                  <p className="text-14 sm:text-16 bg-opacity-30 font-600 text-black">
                    {item?.campaignTitle}
                  </p>
                  <Button
                    variant="contained"
                    type="button"
                    className={`${
                      item?.status === 'scheduled'
                        ? 'bg-quick-social-100 text-quick-social border-quick-social'
                        : item?.status === 'sent'
                        ? 'hover:bg-darkgreen-200 bg-green-100 text-green-900'
                        : item?.status === 'pending'
                        ? 'hover:bg-red-1s00 bg-red-100  text-red-900'
                        : 'bg-darkgreen-200 text-darkgreen border-1 border-green-900'
                    } !py-0 !min-h-0 !max-h-32 my-0 !px-14 !rounded-20`}
                  >
                    {item?.status === 'scheduled'
                      ? 'In Progress'
                      : item?.status === 'sent'
                      ? 'Completed'
                      : item?.status === 'pending'
                      ? 'Incomplete'
                      : 'Unknown Status'}
                  </Button>
                </div>
              </div>
              <div className="bg-indigo-50 rounded-4 p-10 bg-opacity-30">
                <p>{userCurrentBusinessDetails?.name}</p>
                <div className="bg-white rounded-8 p-10 mt-10">
                  {(item?.campaign_platform?.id === 3 || item?.campaign_platform?.id === 1) &&
                  item?.campaign_services_standard_messages?.htmlTemplate ? (
                    <Typography
                      className="mt-8 w-full"
                      dangerouslySetInnerHTML={{
                        __html: item?.campaign_services_standard_messages?.htmlTemplate,
                      }}
                    />
                  ) : (
                    <p>{item?.campaign_services_standard_messages?.messages}</p>
                  )}
                </div>
                <h1 className="text-16 font-600 my-10">{t('quickCampaigns.channel')}</h1>
                <div className="bg-white rounded-8 p-10 font-500">
                  <p>{item?.campaign_services_standard_messages?.campaignPlatform}</p>
                </div>
              </div>
              <div className="flex items-center w-full rounded-md ">
                <div className="w-full sm:w-2/3 flex items-center gap-10">
                  <img
                    src="/assets/images/icon/campaignsTime.svg"
                    alt="Preview"
                    className="sm:w-32 sm:h-32 w-24 h-24 object-cover rounded"
                  />
                  <p className="text-12 sm:text-14 font-500">
                    {moment(Number(item?.cts)).format('MMMM DD, YYYY h:mmA')}
                  </p>
                </div>
                <div className="w-1/3 flex items-center justify-end gap-6">
                  <PersonOutlineOutlinedIcon className="sm:w-32 sm:h-32 w-24 h-24 text-quick-campaign" />
                  <p>{item?.totalCustomers}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <ConformationDialogBox
        open={openDialogueForDelete}
        onClose={handleCloseDeleteDialog}
        onDelete={() => deleteScheduledCampaign(selectedContactId)}
        confirmationText="Are You Sure?"
        additionalInfo={t('quickCampaigns.deleteCampaign')}
        deleteImage="/assets/images/icon/campaignDeleteIcon.png"
        backgroundColor="quick-campaign"
        textColor="quick-campaign"
        buttonText={t('manageBusiness.button.delete')}
      />
    </>
  );
};

export default CreateAndRecentCampaigns;
