import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { t } from 'i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import { Controller, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import _ from '@lodash';
import ReactSelect from 'react-select';
import { FixedSizeList as List } from 'react-window';
import FuseLoading from '@fuse/core/FuseLoading';
import Pagination from 'src/app/component/table/Pagination';
import { useLocation } from 'react-router-dom';
import history from '@history';
import { selectDashboardData, setUserCurrentBusinessDetailsValue } from '../../../store/userSlice';
import manageBusinessData from '../../query/manageBusiness';
import {
  capitalizeFirstLetter,
  getParamsId,
  handleApiRequest,
  sortOptions,
} from '../../common/common';
import DeletePopUp from '../manage-business/PopUp';
import queryData from '../../query/common';
import userData from '../../query/user';
import SupportForPhysicalOrOnline from '../business-setup/supportForPhysicalOrOnline/SupportForPhysicalOrOnline';

const defaultValues = {
  productUrl: '',
};

const Products = () => {
  const location = useLocation();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [pageLoading, setPageLoading] = useState(false);
  const [productsData, setProductsData] = useState([]);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [openDeleteDialogue, setOpenDeleteDialogue] = useState(false);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [productDetails, setProductDetails] = useState();
  const [productId, setProductId] = useState(false);
  const [selectProductType, setSelectProductType] = useState();
  const [productType, setProductType] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [productUrl, setProductUrl] = useState();
  const [reviewPlateForm, setReviewPlateForm] = useState();
  const [plateFormID, setPlateFormID] = useState(
    location.state?.platformsId ||
      localStorage.getItem('platformIdForProduct') ||
      userCurrentBusinessDetails?.reviewplatform?.[0]?.reviewPlatformId
  );
  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const hasSetPlatformID = useRef(false);
  const paramsId = getParamsId();
  useEffect(() => {
    if (
      (location.state?.platformsId === 5 || location.state?.platformsId === 6) &&
      location.state?.openDialogue
    ) {
      setOpenDialogue(true);
    }
  }, [location]);
  const redirect = (productsId) => {
    if (paramsId) {
      history.push(`/productDetails/${productsId}/${paramsId}`, {
        plateFormID,
        productsId,
        paramsId,
      });
    } else {
      history.push(`/productDetails/${productsId}`, { plateFormID });
    }
  };

  const CustomMenuList = (props) => {
    const itemHeight = 35;
    const { options, children, maxHeight } = props;
    if (options.length > 0) {
      return (
        <div>
          {children?.length > 0 ? (
            <List height={maxHeight} itemCount={children.length} itemSize={itemHeight}>
              {({ index, style }) => (
                <div style={style} className="bg-white relative">
                  {children[index]}
                </div>
              )}
            </List>
          ) : (
            <div className="text-center py-10 my-1">
              {t('manageBusiness.addBusinessSteps.addBusiness.form.businessType.businessNotFound')}
            </div>
          )}
        </div>
      );
    }
    return <div className="p-16">Loading....</div>;
  };

  const handleSelectProductType = (selectedOption) => {
    setSelectProductType(selectedOption ? selectedOption.value : null);
  };

  const handleInputChange = (value) => {
    setInputValue(value);
  };

  const filteredOptions = useMemo(() => {
    return sortOptions(inputValue, productType);
  }, [inputValue, productType]);

  const schema = yup.object().shape({
    productUrl: yup
      .string()
      .trim()
      .required(
        t('manageBusiness.addBusinessSteps.amazonProduct.dialog.productName.validationMessage')
      ),
  });

  const { control, watch, reset, handleSubmit, formState, getValues, setValue, setError } = useForm(
    {
      mode: 'onChange',
      defaultValues,
      resolver: yupResolver(schema),
    }
  );
  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {
    const filterData = userCurrentBusinessDetails?.reviewplatform?.filter(
      (reviewplatform) => parseInt(reviewplatform.reviewPlatformId, 10) !== 7
    );
    setReviewPlateForm(filterData);
  }, [userCurrentBusinessDetails?.reviewplatform]);

  useEffect(() => {
    const fetchBusinessType = async () => {
      try {
        const payload = {
          query: queryData.getOnlineBusinessProductType,
        };
        const result = await handleApiRequest(payload);
        if (result?.getOnlineBusinessProductType?.status === 200) {
          setProductType(
            result?.getOnlineBusinessProductType?.data.map((idx) => ({
              value: idx.id,
              label: idx.bType,
            }))
          );
        } else {
          enqueueSnackbar(result?.getOnlineBusinessProductType?.message, {
            variant: 'error',
            autoHideDuration: 2000,
          });
        }
      } catch (error) {
        console.error('Error fetching product types:', error);
      }
    };
    if (userCurrentBusinessDetails?.isOnlineBusiness) {
      fetchBusinessType();
    }
  }, [enqueueSnackbar, userCurrentBusinessDetails?.isOnlineBusiness]);

  const fetchAmazonData = useCallback(
    async (rowPage) => {
      setPageLoading(true);
      if (userCurrentBusinessDetails?.id) {
        try {
          const payload = {
            query: manageBusinessData.getAmazonProduct,
            variables: {
              userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
              pageNumber: page,
              pageSize: rowPage || rowsPerPage,
            },
          };
          const result = await handleApiRequest(payload);
          setPageLoading(false);
          if (result?.getAmazonProduct?.status === 200) {
            setProductsData(result?.getAmazonProduct?.data);
            setTotalCount(result?.getAmazonProduct?.totalCount);
          } else {
            enqueueSnackbar(result?.getAmazonProduct?.message, {
              variant: 'error',
              autoHideDuration: 2000,
            });
          }
          return result;
        } catch (error) {
          console.error('Error fetching Amazon products:', error);
        }
      }
      return null;
    },
    [userCurrentBusinessDetails?.id, page, rowsPerPage, enqueueSnackbar]
  );

  const fetchFlipCartData = useCallback(
    async (rowPage) => {
      setPageLoading(true);
      if (userCurrentBusinessDetails?.id) {
        try {
          const payload = {
            query: manageBusinessData.getFlipkartProduct,
            variables: {
              userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
              pageNumber: page,
              pageSize: rowPage || rowsPerPage,
            },
          };
          const result = await handleApiRequest(payload);
          setPageLoading(false);
          if (result?.getFlipkartProduct?.status === 200) {
            setProductsData(result?.getFlipkartProduct?.data);
            setTotalCount(result?.getFlipkartProduct?.totalCount);
          } else {
            enqueueSnackbar(result?.getFlipkartProduct?.message, {
              variant: 'error',
              autoHideDuration: 2000,
            });
          }
          return result;
        } catch (error) {
          console.error('Error fetching Flipkart products:', error);
        }
      }
      return null;
    },
    [userCurrentBusinessDetails?.id, page, rowsPerPage, enqueueSnackbar]
  );

  useEffect(() => {
    if (parseInt(plateFormID, 10) === 6) {
      fetchFlipCartData();
    } else if (parseInt(plateFormID, 10) === 5) {
      fetchAmazonData();
    }
  }, [plateFormID, fetchAmazonData, fetchFlipCartData]);

  const handleRemove = () => {
    setOpenDialogue(false);
    setProductDetails('');
    setProductUrl('');
    setValue('productUrl', '');
    setSelectProductType('');
    setRefreshIcon(false);
  };

  const verifyLink = async () => {
    setRefreshIcon(true);
    try {
      const payload = {
        query:
          parseInt(plateFormID, 10) === 5
            ? manageBusinessData.fetchAmazonApiData
            : parseInt(plateFormID, 10) === 6 && manageBusinessData.fetchFlipkartProduct,
        variables: {
          url: getValues('productUrl'),
        },
      };
      const result = await handleApiRequest(payload);
      setRefreshIcon(false);
      const res =
        parseInt(plateFormID, 10) === 5
          ? result?.fetchAmazonApiData
          : parseInt(plateFormID, 10) === 6 && result?.fetchFlipkartProduct;
      if (res?.status === 200) {
        setProductUrl(getValues('productUrl'));
        setProductDetails(res?.data);
      } else {
        enqueueSnackbar(res?.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    } catch (error) {
      console.error('Error verifying link:', error);
    }
  };

  const onSubmit = async (submitData) => {
    try {
      setRefreshIcon(true);
      const payload = {
        query:
          parseInt(plateFormID, 10) === 5
            ? manageBusinessData.addAmazonProductV1
            : parseInt(plateFormID, 10) === 6 && manageBusinessData.addFlipkartProductV1,
        variables: {
          productTypeId: parseInt(selectProductType?.value, 10),
          url: submitData?.productUrl,
          userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
        },
      };
      const result = await handleApiRequest(payload);
      setRefreshIcon(false);
      const res =
        parseInt(plateFormID, 10) === 5
          ? result?.addAmazonProductV1
          : parseInt(plateFormID, 10) === 6 && result?.addFlipkartProductV1;
      if (res?.status === 200) {
        const object = {
          query: userData.getDashboard,
          variables: {
            businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          },
        };
        const results = await handleApiRequest(object);
        dispatch(setUserCurrentBusinessDetailsValue(results?.getDashboard?.data?.[0]));
        if (parseInt(plateFormID, 10) === 5) {
          fetchAmazonData();
        } else if (parseInt(plateFormID, 10) === 6) {
          fetchFlipCartData();
        }
        handleRemove();
        setOpenDialogue(false);
      } else {
        enqueueSnackbar(res?.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  };

  const changeReviewPlatForm = (id) => {
    const findPlateForm = reviewPlateForm.find(
      (item) => parseInt(item.reviewPlatformId, 10) === parseInt(id, 10)
    );
    const currentPlatformId = localStorage.getItem('platformIdForProduct');

    if (
      findPlateForm?.reviewPlatformId &&
      currentPlatformId !== findPlateForm.reviewPlatformId.toString()
    ) {
      setPlateFormID(findPlateForm?.reviewPlatformId);
      localStorage.setItem('platformIdForProduct', findPlateForm.reviewPlatformId);
      setPage(1);
      setRowsPerPage(10);
    }
  };

  const openDeletePopup = (id) => {
    setProductId(id);
    setOpenDeleteDialogue(true);
  };

  const deleteAmazonOrFlipkartProduct = async () => {
    if (productId) {
      const obj =
        parseInt(plateFormID, 10) === 5
          ? {
              query: manageBusinessData.deleteAmazonProduct,
              variables: {
                amazonProductId: parseInt(productId, 10),
              },
            }
          : parseInt(plateFormID, 10) === 6 && {
              query: manageBusinessData.deleteFlipkartProduct,
              variables: {
                flipkartProductId: parseInt(productId, 10),
                userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
              },
            };
      try {
        setRefreshIcon(true);
        setPageLoading(true);
        const result = await handleApiRequest(obj);
        setRefreshIcon(false);
        const res =
          parseInt(plateFormID, 10) === 5
            ? result?.deleteAmazonProduct
            : parseInt(plateFormID, 10) === 6 && result?.deleteFlipkartProduct;
        if (res?.status === 200) {
          handelCancelDeleteProduct();
          const object = {
            query: userData.getDashboard,
            variables: {
              businessId: parseInt(userCurrentBusinessDetails?.id, 10),
            },
          };
          const results = await handleApiRequest(object);
          dispatch(setUserCurrentBusinessDetailsValue(results?.getDashboard?.data?.[0]));
          if (parseInt(plateFormID, 10) === 5) {
            const resultData = await fetchAmazonData();
            if (resultData?.getAmazonProduct?.data.length === 0) {
              setPage(page - 1);
            }
          } else if (parseInt(plateFormID, 10) === 6) {
            const resultData = await fetchFlipCartData();
            if (resultData?.getFlipkartProduct?.data.length === 0) {
              setPage(page - 1);
            }
          }
          setPageLoading(false);
        } else {
          enqueueSnackbar(res?.message, {
            variant: 'error',
            autoHideDuration: 2000,
          });
        }
      } catch (error) {
        console.error('Error deleting product:', error);
      }
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handelCancelDeleteProduct = () => {
    setOpenDeleteDialogue(false);
    setProductId('');
  };

  if (pageLoading) {
    return <FuseLoading />;
  }

  return (
    <div className="p-20 lg:p-32 h-full">
      {userCurrentBusinessDetails?.isOnlineBusiness ? (
        <>
          <div className="sm:flex w-full items-center justify-between mb-36">
            <Typography
              initial={{ x: -20 }}
              animate={{ x: 0, transition: { delay: 0.2 } }}
              delay={300}
              className="sm:flex text-20 md:text-28 font-bold mb-16 md:mb-0"
            >
              {t('products.title')}
            </Typography>
            <div className="sm:flex gap-24">
              {userCurrentBusinessDetails?.isOnlineBusiness && (
                <div className="sm:items-center justify-end md:flex mb-20 md:mb-0 text-left">
                  <Select
                    className="rounded-md h-40 bg-white text-16 w-full min-w-256 leading-5 shadow-none capitalize"
                    variant="filled"
                    sx={{ '& .MuiFilledInput-input': { pt: '8px', zIndex: '10' } }}
                    value={
                      localStorage.getItem('platformIdForProduct') || parseInt(plateFormID, 10)
                    }
                    fullWidth
                    disabled={userCurrentBusinessDetails?.reviewplatform?.length === 1}
                    onChange={(e) => changeReviewPlatForm(e?.target?.value)}
                    IconComponent={() => (
                      <ArrowDropDownOutlinedIcon className="text-28 align-middle absolute right-14 text-black ml-2" />
                    )}
                  >
                    {userCurrentBusinessDetails?.reviewplatform
                      ?.filter((item) => parseInt(item?.reviewPlatformId, 10) !== 7)
                      .map((review, i) => {
                        return (
                          <MenuItem className="capitalize" value={review?.reviewPlatformId} key={i}>
                            <div className="flex gap-8">
                              <img className="w-20" src={review?.logo} alt="" />{' '}
                              <Typography>
                                {' '}
                                {review?.name && capitalizeFirstLetter(review.name)}
                              </Typography>
                            </div>
                          </MenuItem>
                        );
                      })}
                  </Select>
                </div>
              )}
              <Button
                className="bg-quick-review rounded-md text-white px-20 hover:bg-quick-review"
                onClick={() => setOpenDialogue(true)}
              >
                {t('products.addNewProduct')}
              </Button>
            </div>
          </div>
          <div className="bg-white overflow-scroll p-20">
            {productsData?.length > 0 ? (
              <>
                {productsData?.map((item, index) => {
                  return (
                    <div
                      className="bg-gray-100 flex flex-col justify-center sm:justify-start items-center sm:items-start md:flex-row rounded-md p-12 mb-12 w-full"
                      key={index}
                    >
                      <div className="w-2/12 flex justify-start items-center">
                        <div className="img-fluid bg-white w-112 h-112 flex items-center justify-center overflow-hidden rounded-md shadow">
                          <img
                            src={item?.profileUrl}
                            className="object-contain w-full h-full"
                            alt="..."
                          />
                        </div>
                      </div>
                      <div className="w-full">
                        <span className="block w-full sm:w-10/12 mt-10 sm:mt-0 md:text-16 text-14 text-center sm:text-start font-semibold capitalize items-center overflow-hidden sm:whitespace-nowrap text-ellipsis	mb-14 sm:max-w-640">
                          {item?.title}
                        </span>
                        <div className="w-full flex justify-between">
                          <div>
                            <div className="sm:inline-block bg-white rounded-md px-10 py-6 mr-10">
                              <span className="font-500">
                                {t('products.ProductID')}{' '}
                                <span className="pl-20"> {item?.placeId}</span>
                              </span>
                            </div>
                            <div className="sm:inline-block bg-white rounded-md px-10 py-6 mr-10">
                              <span className="sm:inline-block font-medium">
                                <span>
                                  <Icon className="text-16 text-yellow-800 align-middle mr-7 -mt-2">
                                    star
                                  </Icon>
                                  {item?.rating}/5
                                </span>
                                <span className="inline-block ml-6 sm:ml-32 mt-4 sm:mt-0">
                                  <span>{item?.totalReviews}</span> Reviews
                                </span>
                              </span>
                            </div>
                          </div>
                          <div className="flex justify-center items-center gap-10">
                            <Button
                              variant="contained"
                              color="inherit"
                              className="inline-block md:text-14 font-medium text-grey-700 disabled:text-black rounded-md p-0 bg-white min-h-32 min-w-32 w-32 h-32"
                              aria-label="delete"
                              onClick={() => openDeletePopup(item?.id)}
                              type="button"
                            >
                              <Icon className="text-20 text-red align-middle">delete</Icon>
                            </Button>
                            <Button
                              variant="contained"
                              color="inherit"
                              className="inline-block md:text-14 font-medium text-grey-700 disabled:text-black rounded-md p-0 bg-white min-h-32 min-w-32 w-32 h-32"
                              aria-label="settings"
                              onClick={() => redirect(item?.id)}
                              type="button"
                            >
                              <Icon className="text-20 text-quick-review align-middle">
                                settings
                              </Icon>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="text-center py-192">
                <img
                  className="m-auto w-144 mb-16"
                  src="/assets/images/business/products.png"
                  alt=""
                />
                {parseInt(plateFormID, 10) === 5 && (
                  <div>
                    <Typography className="font-semibold text-20 my-16">
                      {t('products.noAmazonProduct')}
                    </Typography>
                    <Typography className="text-16 max-w-640 m-auto">
                      {t('products.amazonProductMessage')}
                    </Typography>
                    <Button
                      variant="contained"
                      className="rounded-md mt-14 px-28 mx-auto"
                      color="secondary"
                      size="small"
                      type="button"
                      onClick={() => setOpenDialogue(true)}
                    >
                      {t('products.addAmazonProduct')}
                    </Button>
                  </div>
                )}
                {parseInt(plateFormID, 10) === 6 && (
                  <div>
                    <Typography className="font-semibold text-20 my-16">
                      {t('products.noFlipKartProduct')}
                    </Typography>
                    <Typography className="text-16 max-w-640 m-auto">
                      {t('products.flipkartProductMessage')}
                    </Typography>
                    <Button
                      variant="contained"
                      className="rounded-md mt-14 px-28 mx-auto"
                      color="secondary"
                      size="small"
                      type="button"
                      onClick={() => setOpenDialogue(true)}
                    >
                      {t('products.addFlipkartProduct')}
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
          <Pagination
            totalCount={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
          <Dialog
            classes={{
              paper:
                'm-24 p-28 border-t-8 border-solid border-t-quick-review border-quick-review rounded-md',
            }}
            open={openDialogue}
            fullWidth
            maxWidth="xs"
          >
            <DialogTitle className="p-0 mb-28">
              <Icon
                onClick={handleRemove}
                className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
              >
                cancel
              </Icon>
              {parseInt(plateFormID, 10) === 5 && (
                <Typography className="text-20 font-semibold text-black border-b-1 border-dashed border-grey-500 w-full pb-10">
                  {t('manageBusiness.addBusinessSteps.amazonProduct.pageTitle')}
                </Typography>
              )}
              {parseInt(plateFormID, 10) === 6 && (
                <Typography className="text-20 font-semibold text-black border-b-1 border-dashed border-grey-500 w-full pb-10">
                  {t('manageBusiness.addBusinessSteps.flipkartProduct.pageTitle')}
                </Typography>
              )}
            </DialogTitle>

            <form
              noValidate
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col md:overflow-hidden"
            >
              <DialogContent className="p-0">
                <div className="block mb-14">
                  {parseInt(plateFormID, 10) === 5 && (
                    <InputLabel
                      htmlFor="productUrl"
                      className="text-16 font-medium mb-12 text-grey-900 flex items-center justify-between"
                    >
                      {t('manageBusiness.addBusinessSteps.amazonProduct.dialog.productName.name')}
                    </InputLabel>
                  )}
                  {parseInt(plateFormID, 10) === 6 && (
                    <InputLabel
                      htmlFor="productUrl"
                      className="text-16 font-medium mb-12 text-grey-900 flex items-center justify-between"
                    >
                      {t('manageBusiness.addBusinessSteps.flipkartProduct.dialog.productName.name')}
                    </InputLabel>
                  )}
                  <Controller
                    control={control}
                    name="productUrl"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        placeholder={
                          parseInt(plateFormID, 10) === 5
                            ? t(
                                'manageBusiness.addBusinessSteps.amazonProduct.dialog.productName.placeHolder'
                              )
                            : parseInt(plateFormID, 10) === 6 &&
                              t(
                                'manageBusiness.addBusinessSteps.flipkartProduct.dialog.productName.placeHolder'
                              )
                        }
                        size="small"
                        id="productUrl"
                        error={!!errors.productUrl}
                        helperText={errors?.productUrl?.message}
                        variant="outlined"
                        required
                        fullWidth
                      />
                    )}
                  />
                </div>
                {(!productDetails || productUrl !== getValues('productUrl')) && (
                  <Button
                    className="md:text-16 font-medium disabled:text-black rounded-md min-w-128"
                    variant="contained"
                    color="secondary"
                    size="small"
                    type="button"
                    onClick={() => verifyLink()}
                    disabled={_.isEmpty(dirtyFields) || !isValid || refreshIcon}
                  >
                    {t('manageBusiness.addBusinessSteps.amazonProduct.dialog.button')}

                    {refreshIcon && (
                      <CircularProgress size={24} className="text-quick-review absolute mx-auto" />
                    )}
                  </Button>
                )}
                {productDetails && productUrl === getValues('productUrl') ? (
                  <>
                    <h4 className="text-14 md:text-16 font-medium mb-12 mt-48">
                      {' '}
                      {t('manageBusiness.addBusinessSteps.amazonProduct.dialog.productDetail')}
                    </h4>
                    <div className="bg-grey-100 rounded-md p-10 w-full flex items-center">
                      <img
                        src={productDetails.profile_url || productDetails?.product_image}
                        className="img-fluid max-w-80 rounded-md shadow mr-10"
                        alt="..."
                      />
                      <div>
                        <span className="block w-full md:text-12 text-12 font-medium capitalize items-center">
                          {productDetails?.title}
                        </span>
                      </div>
                    </div>
                    <div className="mt-12">
                      <Typography className="text-14 md:text-16 font-medium mb-4">
                        {t('manageBusiness.addBusinessSteps.amazonProduct.dialog.productType')}
                      </Typography>
                      <ReactSelect
                        labelId="businessType"
                        placeholder={t('manageBusiness.addBusinessSteps.selectProductType')}
                        id="businessType"
                        name="businessType"
                        inputValue={inputValue}
                        onChange={(e) => {
                          handleSelectProductType(e);
                        }}
                        options={productType}
                        onInputChange={handleInputChange}
                        value={productType.find((op) => {
                          return op.value === selectProductType;
                        })}
                        components={{
                          MenuList: CustomMenuList,
                        }}
                      />
                    </div>
                    <div className="text-center">
                      <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        className="md:text-16 font-medium disabled:bg-gray disabled:text-black rounded-md px-28 py-0 mt-48 min-w-256"
                        aria-label="add"
                        disabled={refreshIcon}
                      >
                        {t('manageBusiness.addBusinessSteps.amazonProduct.dialog.addProduct')}
                        {refreshIcon && (
                          <CircularProgress
                            size={24}
                            className="text-quick-review absolute mx-auto"
                          />
                        )}
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    {parseInt(plateFormID, 10) === 5 && (
                      <div>
                        <h4 className="text-center text-14 md:text-16 font-medium mb-28 mt-32">
                          {' '}
                          {t('manageBusiness.addBusinessSteps.amazonProduct.dialog.guideTitle')}
                        </h4>
                        <img
                          src="/assets/images/business/amzon.png"
                          className="img-fluid mx-auto max-w-320 w-full"
                          alt="..."
                        />
                      </div>
                    )}
                    {parseInt(plateFormID, 10) === 6 && (
                      <div>
                        <h4 className="text-center text-14 md:text-16 font-medium mb-28 mt-32">
                          {t('manageBusiness.addBusinessSteps.flipkartProduct.dialog.guideTitle')}
                        </h4>
                        <img
                          src="/assets/images/business/flipkartProductGuide.png"
                          className="img-fluid mx-auto max-w-320 w-full"
                          alt="..."
                        />
                      </div>
                    )}
                  </>
                )}
              </DialogContent>
            </form>
          </Dialog>
          <DeletePopUp
            openValue={openDeleteDialogue}
            eventCall={deleteAmazonOrFlipkartProduct}
            cancelCall={handelCancelDeleteProduct}
            refreshIcon={refreshIcon}
            popUpDescription={t('products.deleteProductMessage')}
            popUpMessage={t('products.areYouSure')}
            popUpButtonName={t('products.delete')}
          />
        </>
      ) : (
        <SupportForPhysicalOrOnline />
      )}
    </div>
  );
};

export default Products;
