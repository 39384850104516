import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  Icon,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import { useState } from 'react';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import CloseIcon from '@mui/icons-material/Close';
import CKEditor from 'react-ckeditor-component';
import businessData from 'src/app/main/query/business';
import { handleApiRequest } from 'src/app/main/common/common';
import { useSelector } from 'react-redux';
import { selectDashboardData } from 'app/store/userSlice';

const CreateTemplate = (props) => {
  const { openDialog, setOpenDialog, category, tabValue, fetchTemplate } = props;
  const [selectCategory, setSelectCategory] = useState();
  const [showVariables, setShowVariables] = useState(false);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [htmlTemplate, setHtmlTemplate] = useState();
  const [copyLink, setCopyLink] = useState(true);
  const [copyName, setCopyName] = useState();
  const [createTemplateDetails, setCreateTemplateDetails] = useState({
    templateName: '',
    message: '',
    htmlSubject: '',
  });

  const handleCreateTemplate = async () => {
    try {
      setRefreshIcon(true);
      const payload = {
        query: businessData.createCampaignServicesStandardMessages,
        variables: {
          userBusinessId: Number(userCurrentBusinessDetails?.id),
          campaignPlatformId: Number(tabValue),
          title: createTemplateDetails?.templateName,
          messagesBusinessTypeId: Number(selectCategory),
          ...(Number(tabValue) === 1 && {
            htmlSubject: createTemplateDetails?.htmlSubject,
            htmlTemplate: `${htmlTemplate} ${
              category?.find((item) => item?.id === selectCategory)?.type === 'Invite Review'
                ? userCurrentBusinessDetails?.businessQRId
                : ''
            }`,
          }),
          ...(Number(tabValue) === 2 && {
            messages: `${createTemplateDetails?.message} ${
              category?.find((item) => item?.id === selectCategory)?.type === 'Invite Review'
                ? userCurrentBusinessDetails?.businessQRId
                : ''
            }`,
          }),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.createCampaignServicesStandardMessages?.status === 200) {
        setCreateTemplateDetails({
          templateName: '',
          message: '',
        });
        setOpenDialog(false);
        setSelectCategory();
        fetchTemplate();
      }
    } catch (error) {
      console.error('Error creating template:', error);
    } finally {
      setRefreshIcon(false);
    }
  };

  const handleCopyClick = (id) => {
    setCopyName(id);
    const textToCopy = document.getElementById(id).innerText;
    setCopyLink(true);
    navigator.clipboard.writeText('').then(() => {
      return navigator.clipboard.writeText(textToCopy);
    });
    setTimeout(() => {
      setCopyLink(false);
    }, 1000);
  };

  const handleEmailContent = async (event) => {
    setHtmlTemplate(event?.editor?.getData());
  };

  return (
    <div>
      <Dialog
        classes={{
          paper: 'm-24 sm:p-28 pt-0 rounded-md',
        }}
        open={openDialog}
        fullWidth
        maxWidth="sm"
      >
        <div className="flex items-center justify-between">
          <Typography className="font-bold text-20">
            {t('quickCampaign.template.createTemplate')}
          </Typography>
        </div>
        <Icon
          onClick={() => {
            setOpenDialog(false);
            setSelectCategory();
            setCreateTemplateDetails({
              templateName: '',
              message: '',
            });
          }}
          className="text-24 absolute right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
        >
          cancel
        </Icon>
        <Typography className="mt-16 font-medium text-gray font-semibold text-14">
          {t('quickCampaign.template.templateCategory')}
        </Typography>
        <div className="quick-campaign w-full">
          <Select
            className="mt-8 border-quick-campaign w-full"
            size="small"
            value={selectCategory || 0}
            onChange={(e) => setSelectCategory(e.target.value)}
          >
            <MenuItem className="font-medium" value="0" disabled>
              {t('quickCampaign.template.selectCategory')}
            </MenuItem>
            {category?.length > 0 &&
              category?.map((item, i) => {
                return (
                  <MenuItem
                    className="hover:bg-quick-campaign hover:text-white font-medium bg-gray-A500"
                    key={i}
                    value={item?.id}
                  >
                    {item?.type}
                  </MenuItem>
                );
              })}
          </Select>
        </div>
        <div className="mb-8">
          <Typography className="mt-16 mb-8 font-medium text-gray font-semibold text-14">
            {t('quickCampaign.template.templateDetails')}
          </Typography>
          <div className="bg-gray-A500 rounded-md">
            <div className="p-16">
              <Typography className="font-normal mb-12 text-14">
                {t('quickCampaign.template.templateName')}
              </Typography>
              <div className="quick-campaign">
                <TextField
                  className="w-full bg-white"
                  value={createTemplateDetails?.templateName}
                  size="small"
                  onChange={(e) =>
                    setCreateTemplateDetails({
                      ...createTemplateDetails,
                      templateName: e.target.value,
                    })
                  }
                  placeholder={t('quickCampaign.template.enterTemplateNameHere')}
                />
              </div>
              {tabValue === 1 && (
                <>
                  <Typography className="font-normal my-12 text-14">
                    {t('inviteReviews.subject')}
                  </Typography>
                  <TextField
                    className="w-full bg-white"
                    value={createTemplateDetails?.htmlSubject}
                    size="small"
                    onChange={(e) =>
                      setCreateTemplateDetails({
                        ...createTemplateDetails,
                        htmlSubject: e.target.value,
                      })
                    }
                    placeholder={t('quickCampaign.template.htmlSubject')}
                  />
                </>
              )}
              <div className="flex justify-between">
                <Typography className="font-normal text-14 my-12">
                  {tabValue === 2
                    ? t('inviteReviews.messageContent')
                    : t('inviteReviews.emailContent')}
                </Typography>
                <Button
                  className="font-semibold text-quick-campaign my-12 p-0 h-20 min-h-20 hover:bg-gray-100"
                  onClick={() => setShowVariables(true)}
                >
                  {t('quickCampaign.template.addVariable')}
                </Button>
              </div>
              {showVariables && (
                <div className="bg-quick-campaign-100 mb-12 rounded-md relative shadow border border-quick-campaign border-dashed p-20">
                  <div className="flex justify-between items-center">
                    <Typography className="text-16 text-black font-semibold">
                      {t('quickCampaign.template.addVariable')}
                    </Typography>
                    <Button
                      className="p-0 min-w-28 min-h-0 hover:bg-quick-campaign-100 max-h-20 h-20"
                      onClick={() => setShowVariables(false)}
                    >
                      <CloseIcon />
                    </Button>
                  </div>
                  <div className="mt-12 relative">
                    <Typography className="text-14 text-black font-medium pb-8">
                      {t('inviteReviews.message.firstName.message')}
                    </Typography>
                    <div className="bg-white p-8 flex justify-between items-center">
                      <Typography id="fullName" className="pl-8">
                        {`[${t('inviteReviews.message.fullName.name')}]`}
                      </Typography>
                      <Button
                        className="p-0 min-w-28 min-h-0 hover:bg-white max-h-20 h-20"
                        onClick={() => handleCopyClick('fullName')}
                      >
                        <ContentCopyRoundedIcon className="text-quick-campaign" />
                      </Button>
                      {copyLink && copyName === 'fullName' && (
                        <span className="absolute bg-quick-campaign text-white text-12 px-8 rounded top-20 -right-28 text-center duration-300 animate-bounce">
                          {t('business.selectedBusinessDetail.labels.copied')}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="mt-20 relative">
                    <Typography className="text-14 text-black font-medium pb-8">
                      {`${t('inviteReviews.message.businessName.message')}`}
                    </Typography>
                    <div className="bg-white p-8 flex justify-between items-center">
                      <Typography id="businessName" className="pl-8">
                        {`[${t('inviteReviews.message.businessName.name')}]`}
                      </Typography>
                      <Button
                        className="p-0 min-w-28 min-h-0 hover:bg-white max-h-20 h-20"
                        onClick={() => handleCopyClick('businessName')}
                      >
                        <ContentCopyRoundedIcon className="text-quick-campaign" />
                      </Button>
                      {copyLink && copyName === 'businessName' && (
                        <span className="absolute bg-quick-campaign text-white text-12 px-8 rounded top-20 -right-28 text-center duration-300 animate-bounce">
                          {t('business.selectedBusinessDetail.labels.copied')}
                        </span>
                      )}
                    </div>
                  </div>
                  {category?.length > 0 &&
                    category?.find((item) => item?.id === selectCategory)?.type ===
                      'Invite Review' && (
                      <div className="mt-20 relative">
                        <Typography className="text-14 text-black font-medium pb-8">
                          {`${t('inviteReviews.message.reviewUrl.message')}`}
                        </Typography>
                        <div className="bg-white p-8 flex justify-between items-center">
                          <Typography id="reviewUrl" className="pl-8">
                            {`[${t('inviteReviews.message.reviewUrl.name')}]`}
                          </Typography>
                          <Button
                            className="p-0 min-w-28 min-h-0 hover:bg-white max-h-20 h-20"
                            onClick={() => handleCopyClick('reviewUrl')}
                          >
                            <ContentCopyRoundedIcon className="text-quick-campaign" />
                          </Button>
                          {copyLink && copyName === 'reviewUrl' && (
                            <span className="absolute bg-quick-campaign text-white text-12 px-8 rounded top-20 -right-28 text-center duration-300 animate-bounce">
                              {t('business.selectedBusinessDetail.labels.copied')}
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                </div>
              )}
              {tabValue === 2 && (
                <div className="border !border-gray-500 bg-white border-solid rounded-md p-2">
                  <TextField
                    className="w-full"
                    value={createTemplateDetails?.message}
                    onChange={(e) =>
                      setCreateTemplateDetails({
                        ...createTemplateDetails,
                        message: e.target.value,
                      })
                    }
                    rows={5}
                    multiline
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: 'none',
                        },
                      },
                    }}
                    placeholder={t('quickCampaign.template.typeMessage')}
                  />
                  {category?.length > 0 &&
                    category?.find((item) => item?.id === selectCategory)?.type ===
                      'Invite Review' && (
                      <Typography className="max-content bg-gray-200 rounded-md mb-16 mx-12 p-8">
                        {userCurrentBusinessDetails?.businessQRId}
                      </Typography>
                    )}
                </div>
              )}
              {tabValue === 1 && (
                <>
                  <CKEditor
                    activeClass="editor"
                    config={{
                      allowedContent: true,
                      versionCheck: false,
                    }}
                    content={htmlTemplate || ''}
                    events={{
                      change: handleEmailContent,
                    }}
                  />
                  {category?.length > 0 &&
                    category?.find((item) => item?.id === selectCategory)?.type ===
                      'Invite Review' && (
                      <Typography className="max-content bg-gray-200 rounded-md my-16 p-8">
                        {userCurrentBusinessDetails?.businessQRId}
                      </Typography>
                    )}
                </>
              )}
            </div>
          </div>
        </div>
        <DialogActions className="p-0 justify-end">
          <Button
            variant="contained"
            color="quickCampaign"
            autoFocus
            onClick={() => handleCreateTemplate()}
            size="medium"
            disabled={
              !selectCategory ||
              refreshIcon ||
              !createTemplateDetails?.templateName ||
              (tabValue === 2 && !createTemplateDetails?.message) ||
              (tabValue === 1 && (!htmlTemplate || !createTemplateDetails?.htmlSubject))
            }
            className={`rounded-md gap-10 md:px-20 md:py-12 ${
              refreshIcon
                ? 'disabled:bg-gray disabled'
                : 'disabled:opacity-40 disabled:bg-quick-campaign disabled:text-white'
            }`}
          >
            {refreshIcon && (
              <CircularProgress size={24} className="text-quick-campaign absolute mx-auto" />
            )}
            <p className="text-16 font-medium">{t('quickCampaign.campaign.createCampaign')}</p>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CreateTemplate;
