import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';
import { selectDashboardData } from 'src/app/store/userSlice';
import metricsData from '../../query/metrics';
import NoData from './noDataScreen';
import ReviewSentiment from './reviewSentiment';
import { getDataToServer } from '../../common/common';

function ReviewsOverview(props) {
  const { reviewSentiment, reviewPlatformId } = props;
  const [reviewsOverviewValue, setReviewsOverviewValue] = useState();
  const [reviewsOverviewValueY, setReviewsOverviewValueY] = useState();
  const [selectedReviewSentimentId, setSelectedReviewSentimentId] = useState(1);
  const [overviewInfo, setOverViewInfo] = useState(null);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [planStatus, setPlanStatus] = useState();

  useEffect(() => {
    try {
      const fetchData = async () => {
        if (reviewPlatformId) {
          const payload = {
            query: metricsData.getReviewsOverview,
            variables: {
              businessId: parseInt(userCurrentBusinessDetails?.id, 10),
              reviewPlatformId: parseInt(reviewPlatformId, 10),
              reviewPeriodId: parseInt(selectedReviewSentimentId, 10),
            },
          };
          const result = await getDataToServer(payload);
          setPlanStatus(result?.getReviewsOverview?.status);

          if (result?.getReviewsOverview?.data?.reviewsOverviewJsonV1) {
            const parsedData = JSON.parse(result?.getReviewsOverview?.data?.reviewsOverviewJsonV1);

            const changeInPercentageItem = parsedData.find(
              (item) => item.key === 'changeInPercentage'
            );

            if (changeInPercentageItem) {
              setOverViewInfo(changeInPercentageItem.value);
            }

            const overallReviewsCount = parsedData.find(
              (item) => item.key === 'overallReviewsCount'
            );

            overallReviewsCount?.value?.sort((prev, curr) => {
              if (
                moment(moment.unix(prev.startDate).format()).isBefore(
                  moment.unix(curr.startDate).format(),
                  'days'
                )
              ) {
                return 1;
              }
              return -1;
            });

            const reviewCounts = overallReviewsCount?.value?.slice(0, 5)?.map((item) => {
              const reviewCountItem = item.find((subItem) => subItem.key === 'reviewCount');
              return reviewCountItem ? reviewCountItem.value : 0;
            });
            const reviewPeriods = overallReviewsCount?.value?.slice(0, 5)?.map((item) => {
              const startDateItem = item.find((subItem) => subItem.key === 'startDate');
              const endDateItem = item.find((subItem) => subItem.key === 'endDate');
              return startDateItem && endDateItem
                ? `${
                    selectedReviewSentimentId === 1
                      ? `${moment.unix(startDateItem.value).format('DD MMM')} -`
                      : ''
                  }  ${
                    selectedReviewSentimentId === 1
                      ? moment.unix(endDateItem.value).format('DD MMM')
                      : moment.unix(endDateItem.value).format('MMM-YY')
                  }`
                : 0;
            });
            setReviewsOverviewValue(reviewCounts);
            setReviewsOverviewValueY(reviewPeriods);
          } else {
            setReviewsOverviewValue();
            setReviewsOverviewValueY();
          }
        }
      };
      fetchData();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [userCurrentBusinessDetails?.id, reviewPlatformId, selectedReviewSentimentId]);

  function getChartSimple(data, dataLabels, index, types) {
    const chart = {
      series: [
        {
          name: 'Reviews',
          data,
        },
      ],
      options: {
        grid: {
          strokeDashArray: 3,
          show: true,
          forceNiceScale: false,
          padding: {
            bottom: 0,
          },
        },
        chart: {
          events: {
            mounted: (charts) => {
              charts.windowResizeHandler();
            },
          },
          type: types,
          stacked: false,
          parentHeightOffset: 0,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ['#67df9d', '#ebebeb', '#ebebeb', '#ebebeb', '#ebebeb'],
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'right',
            },
            distributed: true,
            horizontal: false,
            borderRadius: 3,
            columnWidth: '25px',
          },
        },
        legend: {
          show: false,
          markers: {
            radius: 12,
          },
          onItemClick: {
            toggleDataSeries: false,
          },
        },
        fill: {
          opacity: 1,
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          axisBorder: {
            show: false,
          },
          categories: dataLabels,
          show: false,
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
            offsetY: 0,
          },
        },
        yaxis: {
          show: true,
          axisTicks: {
            show: false,
          },
          labels: {
            align: 'left',
            maxWidth: 350,
            position: 'left',
            offsetX: -15,
            style: {
              fontSize: '14px',
            },
            formatter(value) {
              return Math.round(value);
            },
          },
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
      },
    };

    return (
      <div
        id={`chart${index}`}
        className="flex flex-col flex-auto w-full"
        style={{ overflowX: 'auto', overflowY: 'hidden' }}
      >
        <ReactApexChart
          options={chart?.options}
          series={chart?.series}
          type={chart?.options?.chart?.type}
          height="300px"
          width={dataLabels.length > 8 ? `${dataLabels.length * 60}px` : '100%'}
        />
      </div>
    );
  }

  return (
    <>
      {((planStatus !== 403 && !userCurrentBusinessDetails?.isOnlineBusiness) ||
        parseInt(reviewPlatformId, 10) === 7) && (
        <ReviewSentiment
          reviewSentiment={reviewSentiment}
          sentimentId={[1, 2]}
          onSelectSentiment={setSelectedReviewSentimentId}
        />
      )}
      {reviewsOverviewValue?.length > 0 ? (
        <>
          <div className="inline-block float-right relative md:-mt-72">
            <span
              className={`inline-block align-middle rounded-md border-1 border-solid py-12 px-10 mr-16 ${
                overviewInfo < 0
                  ? ' bg-red-100 border-red'
                  : 'bg-quick-review-100 border-quick-review'
              }`}
            >
              <span
                className={`inline-block align-middle font-semibold text-16 mr-6 ${
                  overviewInfo < 0 ? ' text-red' : 'text-quick-review'
                }`}
              >
                {overviewInfo ? `${Math.abs(overviewInfo).toFixed(0)}%` : '0%'}
              </span>
              <span className="inline-block align-middle bg-white shadow rounded-full w-20 h-20 pt-3">
                {overviewInfo < 0 ? (
                  <img
                    src="/assets/images/business/negativePercentage.png"
                    className="w-full max-w-14 mx-auto "
                    alt="..."
                  />
                ) : (
                  <img
                    src="/assets/images/business/positivePercentage.png"
                    className="w-full max-w-14 mx-auto"
                    alt="..."
                  />
                )}
              </span>
            </span>
            <div className="border-1 border-solid border-grey-200 rounded-md inline-block align-middle">
              <div className="border-b-1 border-solid border-grey-200">
                <span className="inline-block font-semibold text-black w-80 border-r-1 border-solid border-grey-200 px-5 py-2">
                  {selectedReviewSentimentId === 1 ? 'Week' : 'Month'}
                </span>
                <span className="inline-block w-96 text-12 border-r-1 border-solid border-grey-200 px-5 py-2">
                  {reviewsOverviewValueY?.[1]}
                </span>
                <span className="inline-block w-96 text-12 px-5 py-2 font-semibold">
                  {reviewsOverviewValueY?.[0]}
                </span>
              </div>
              <div>
                <span className="inline-block font-semibold text-black w-80 border-r-1 border-solid border-grey-200 px-5 py-2">
                  Reviews
                </span>
                <span className="inline-block w-96 border-r-1 border-solid border-grey-200 px-5 py-2">
                  {reviewsOverviewValue?.[1]}
                </span>
                <span
                  className={`inline-block w-96 px-5 py-2 font-semibold ${
                    overviewInfo < 0 ? 'text-red' : 'text-green'
                  }`}
                >
                  {reviewsOverviewValue?.[0]}
                </span>
              </div>
            </div>
          </div>

          <div className="md:mt-28">
            {reviewsOverviewValue &&
              getChartSimple(reviewsOverviewValue, reviewsOverviewValueY, 1, 'bar')}
          </div>
        </>
      ) : (
        <NoData />
      )}
    </>
  );
}

export default ReviewsOverview;
