import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import CloseIcon from '@mui/icons-material/Close';
import CKEditor from 'react-ckeditor-component';
import history from '@history';
import MessageIcon from '@mui/icons-material/Message';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  DatePicker,
  LocalizationProvider,
  renderTimeViewClock,
  TimePicker,
} from '@mui/x-date-pickers';
import IconButton from '@mui/material/IconButton';
import { useSnackbar } from 'notistack';
import businessData from '../../../query/business';
import { handleApiRequest } from '../../../common/common';

const CampaignDetails = (props) => {
  const {
    createCampaignDetails,
    setCreateCampaignDetails,
    campaignName,
    setCampaignName,
    stepsCount,
    businessDetails,
    setStepsCount,
    selectedContact,
    state,
    tabValue,
    contactsData,
    csvContactData,
  } = props;
  const [showVariables, setShowVariables] = useState(false);
  const [isSchedule, setIsSchedule] = useState(false);
  const [scheduledTime, setScheduledTime] = useState(
    state?.campaign_services_queue?.[0]?.scheduleDate
      ? new Date(state?.campaign_services_queue?.[0]?.scheduleDate)
      : new Date()
  );
  const [contacts, setContacts] = useState([]);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [copyLink, setCopyLink] = useState(true);
  const [scheduledDate, setScheduledDate] = useState(
    state?.campaign_services_queue?.[0]?.scheduleDate
      ? new Date(state?.campaign_services_queue?.[0]?.scheduleDate)
      : new Date()
  );

  const [scheduleDateAndTime, setScheduledDateAndTime] = useState();
  const [copyName, setCopyName] = useState();
  const [campaignCreateSuccessFully, setCampaignCreateSuccessFully] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (stepsCount === 3) {
      setContacts(selectedContact);
    } else if (stepsCount === 2) {
      if (tabValue === 1) {
        const filterEmailData = contactsData?.filter((item) =>
          createCampaignDetails?.platForm === 1 && tabValue === 1 ? item?.email : true
        );
        setContacts(filterEmailData);
      } else if (tabValue === 2) {
        setContacts(csvContactData);
      }
    }
  }, [
    contactsData,
    selectedContact,
    stepsCount,
    tabValue,
    createCampaignDetails?.platForm,
    csvContactData,
  ]);

  const handleCopyClick = (id) => {
    setCopyName(id);
    const textToCopy = document.getElementById(id).innerText;
    setCopyLink(true);
    navigator.clipboard.writeText('').then(() => {
      return navigator.clipboard.writeText(textToCopy);
    });
    setTimeout(() => {
      setCopyLink(false);
    }, 1000);
  };

  const handleEmailContent = async (event) => {
    setCreateCampaignDetails({
      ...createCampaignDetails,
      template: {
        ...createCampaignDetails.template,
        htmlTemplate: event?.editor?.getData(),
      },
    });
  };

  const handleDateChange = (data) => {
    setScheduledDate(data);
  };

  const formatDateTime = (date, time) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(time.getHours()).padStart(2, '0');
    const minutes = String(time.getMinutes()).padStart(2, '0');
    const seconds = String(time.getSeconds()).padStart(2, '0');
    const timezoneOffset = getTimezoneOffsetString(date);
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${timezoneOffset}`;
  };

  const getTimezoneOffsetString = (date) => {
    const offset = date.getTimezoneOffset();
    const absOffset = Math.abs(offset);
    const hours = String(Math.floor(absOffset / 60)).padStart(2, '0');
    const minutes = String(absOffset % 60).padStart(2, '0');
    const sign = offset <= 0 ? '+' : '-';
    return `${sign}${hours}:${minutes}`;
  };

  const handleTimeChange = (event) => {
    setScheduledTime(event);
  };

  const checkCampaignNameAvailable = async () => {
    try {
      const payload = {
        query: businessData.checkCampaignNameAvailable,
        variables: {
          businessId: Number(businessDetails?.id),
          campaignName,
        },
      };
      const result = await handleApiRequest(payload);
      if (
        result?.checkCampaignNameAvailable?.status === 200 &&
        result?.checkCampaignNameAvailable?.data?.isAvailable
      ) {
        saveCampaignDraft();
      } else {
        enqueueSnackbar(result?.checkCampaignNameAvailable?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('Error in checkCampaignNameAvailable:', error);
    }
  };

  const saveCampaignDraft = async (dateTimeFormat) => {
    try {
      setRefreshIcon(true);
      const payload = {
        query: businessData.saveAndUpdateCampaignDraft,
        variables: {
          businessId: Number(businessDetails?.id),
          campaignName,
          campaignPlatformId: Number(createCampaignDetails?.platForm),
          campaignServicesStandardMessageId: Number(createCampaignDetails?.id),
          ...(stepsCount === 1
            ? {
                isSchedule: false,
                queue: null,
                scheduleDate: null,
              }
            : {
                isSchedule: !!dateTimeFormat,
                scheduleDate: dateTimeFormat || null,
                ...(tabValue === 1
                  ? {
                      queue: selectedContact?.map((item) => {
                        if (createCampaignDetails?.platForm === 1) {
                          return {
                            email: item?.email,
                            htmlSubject: createCampaignDetails?.htmlSubject
                              ?.replace(
                                /\[Customers Name\]/g,
                                `${item?.firstName} ${item?.lastName}`
                              )
                              ?.replace(/\[Business Name\]/g, `${businessDetails?.name}`),
                            htmlTemplate: createCampaignDetails?.htmlTemplate
                              ?.replace(
                                /\[Customers Name\]/g,
                                `${item?.firstName} ${item?.lastName}`
                              )
                              ?.replace(/\[Business Name\]/g, `${businessDetails?.name}`)
                              ?.replace(/\[Review Link\]/g, businessDetails?.businessQRId),
                            phoneNumber: item.phoneNumber,
                            countryCode: item.countryCode,
                            name: `${item?.firstName} ${item?.lastName}`,
                            title: createCampaignDetails?.messages
                              ?.replace(
                                /\[Customers Name\]/g,
                                `${item?.firstName} ${item?.lastName}`
                              )
                              ?.replace(/\[Business Name\]/g, `${businessDetails?.name}`)
                              ?.replace(/\[Review Link\]/g, businessDetails?.businessQRId),
                          };
                        }
                        if (createCampaignDetails?.platForm === 2) {
                          return {
                            phoneNumber: item.phoneNumber,
                            countryCode: item.countryCode,
                            name: `${item?.firstName} ${item?.lastName}`,
                            title: createCampaignDetails?.messages
                              ?.replace(
                                /\[Customers Name\]/g,
                                `${item?.firstName} ${item?.lastName}`
                              )
                              ?.replace(/\[Business Name\]/g, `${businessDetails?.name}`)
                              ?.replace(/\[Review Link\]/g, businessDetails?.businessQRId),
                          };
                        }
                        if (createCampaignDetails?.platForm === 3) {
                          return {
                            phoneNumber: item.phoneNumber,
                            countryCode: item.countryCode,
                            name: `${item?.firstName} ${item?.lastName}`,
                          };
                        }
                        return null;
                      }),
                    }
                  : {
                      csvIds: selectedContact?.map((item) => item?.id),
                    }),
              }),
        },
      };
      const res = await handleApiRequest(payload);
      setRefreshIcon(false);
      if (res?.saveAndUpdateCampaignDraft?.status === 200) {
        if (stepsCount === 3) {
          createCampaign(res?.saveAndUpdateCampaignDraft?.data?.id);
        } else {
          setStepsCount(2);
        }
      } else {
        enqueueSnackbar(res?.saveAndUpdateCampaignDraft?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setRefreshIcon(false);
      console.error('Error in saveCampaignDraft:', error);
    }
  };

  const createCampaign = async (serviceId) => {
    try {
      const payload = {
        query:
          createCampaignDetails?.platForm === 3
            ? businessData.sendCampaignWhatsAppMessagesV1
            : businessData.sendCampaignMessagesV1,
        variables: {
          businessId: Number(businessDetails?.id),
          campaignPlatformId: Number(createCampaignDetails?.platForm),
          isSchedule: false,
          campaignServicesId: Number(serviceId),
        },
      };
      const res = await handleApiRequest(payload);
      const result =
        createCampaignDetails?.platForm === 3
          ? res?.sendCampaignWhatsAppMessagesV1
          : res?.sendCampaignMessagesV1;

      if (result?.status === 200) {
        setCampaignCreateSuccessFully(true);
      } else {
        enqueueSnackbar(result?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('Error in createCampaign:', error);
    }
  };

  const handleScheduleDateAndTime = () => {
    const dateTimeFormat = formatDateTime(scheduledDate, scheduledTime);
    setScheduledDateAndTime(dateTimeFormat);
    setIsSchedule(false);
    saveCampaignDraft(dateTimeFormat);
  };

  return (
    <div className="">
      <Typography className="font-semibold text-16 py-20">
        {t('quickCampaign.campaign.campaignDetails')}
      </Typography>
      <div className="mb-8 bg-white rounded-md">
        <div className="p-16 quick-campaign">
          <Typography className="font-medium mb-12 text-14">
            {t('quickCampaign.campaign.campaignName')}
          </Typography>
          <TextField
            className="w-full bg-white"
            value={campaignName}
            size="small"
            onChange={(e) => setCampaignName(e.target.value)}
            sx={{
              '& .MuiInputBase-input': {
                fontWeight: '600',
                lineHeight: '22px',
                fontSize: 14,
              },
            }}
            placeholder={t('quickCampaign.template.enterTemplateNameHere')}
          />
          <div className="flex justify-between">
            <Typography className="font-medium my-12 text-14">
              {t('quickCampaign.template.templateName')}
            </Typography>
            <Button
              className="font-semibold text-quick-campaign my-12 p-0 h-20 min-h-20 hover:bg-white"
              onClick={() => history.push('quick-campaign/template')}
            >
              {t('quickCampaign.campaign.change')}
            </Button>
          </div>
          <TextField
            className="w-full bg-white"
            value={createCampaignDetails?.title}
            size="small"
            onChange={(e) =>
              setCreateCampaignDetails({
                ...createCampaignDetails,
                title: e.target.value,
              })
            }
            sx={{
              '& .MuiInputBase-input': {
                fontWeight: '600',
                lineHeight: '22px',
                fontSize: 14,
              },
            }}
            placeholder={t('quickCampaign.template.enterTemplateNameHere')}
          />
          {createCampaignDetails?.platForm === 1 && (
            <>
              <Typography className="font-medium my-12 text-14">
                {t('inviteReviews.subject')}
              </Typography>
              <TextField
                className="w-full bg-white"
                value={createCampaignDetails?.htmlSubject}
                size="small"
                onChange={(e) =>
                  setCreateCampaignDetails({
                    ...createCampaignDetails,
                    htmlSubject: e.target.value,
                  })
                }
                sx={{
                  '& .MuiInputBase-input': {
                    fontWeight: '600',
                    lineHeight: '22px',
                    fontSize: 14,
                  },
                }}
                placeholder={t('quickCampaign.template.htmlSubject')}
              />
            </>
          )}
          <div className="flex justify-between">
            <Typography className="font-medium text-14 my-12">
              {createCampaignDetails?.platForm === 2
                ? t('inviteReviews.messageContent')
                : createCampaignDetails?.platForm === 1
                ? t('inviteReviews.emailContent')
                : t('inviteReviews.whatsAppTemplate')}
            </Typography>
            {createCampaignDetails?.platForm !== 3 && (
              <Button
                className="font-semibold text-quick-campaign my-12 p-0 h-20 min-h-20 hover:bg-white"
                onClick={() => setShowVariables(true)}
              >
                {t('quickCampaign.template.addVariable')}
              </Button>
            )}
          </div>
          {showVariables && (
            <div className="bg-quick-campaign-100 mb-12 rounded-md relative shadow border border-quick-campaign border-dashed p-20">
              <div className="flex justify-between items-center">
                <Typography className="text-16 text-black font-semibold">
                  {t('quickCampaign.template.addVariable')}
                </Typography>
                <Button
                  className="p-0 min-w-28 min-h-0 hover:bg-quick-campaign-100 max-h-20 h-20"
                  onClick={() => setShowVariables(false)}
                >
                  <CloseIcon />
                </Button>
              </div>
              <div className="mt-12 relative">
                <Typography className="text-14 text-black font-medium pb-8">
                  {t('inviteReviews.message.fullName.message')}
                </Typography>
                <div className="bg-white p-8 flex justify-between items-center">
                  <Typography id="fullName" className="pl-8">
                    {`[${t('inviteReviews.message.fullName.name')}]`}
                  </Typography>
                  <Button
                    className="p-0 min-w-28 min-h-0 hover:bg-white max-h-20 h-20"
                    onClick={() => handleCopyClick('fullName')}
                  >
                    <ContentCopyRoundedIcon className="text-quick-campaign" />
                  </Button>
                  {copyLink && copyName === 'fullName' && (
                    <span className="absolute bg-quick-campaign text-white text-12 px-8 rounded top-20 -right-28 text-center duration-300 animate-bounce">
                      {t('business.selectedBusinessDetail.labels.copied')}
                    </span>
                  )}
                </div>
              </div>
              <div className="mt-20 relative">
                <Typography className="text-14 text-black font-medium pb-8">
                  {`${t('inviteReviews.message.businessName.message')}`}
                </Typography>
                <div className="bg-white p-8 flex justify-between items-center">
                  <Typography id="businessName" className="pl-8">
                    {`[${t('inviteReviews.message.businessName.name')}]`}
                  </Typography>
                  <Button
                    className="p-0 min-w-28 min-h-0 hover:bg-white max-h-20 h-20"
                    onClick={() => handleCopyClick('businessName')}
                  >
                    <ContentCopyRoundedIcon className="text-quick-campaign" />
                  </Button>
                  {copyLink && copyName === 'businessName' && (
                    <span className="absolute bg-quick-campaign text-white text-12 px-8 rounded top-20 -right-28 text-center duration-300 animate-bounce">
                      {t('business.selectedBusinessDetail.labels.copied')}
                    </span>
                  )}
                </div>
              </div>
              {state?.categoryName === 'Invite Review' && (
                <div className="mt-20 relative">
                  <Typography className="text-14 text-black font-medium pb-8">
                    {`${t('inviteReviews.message.reviewUrl.message')}`}
                  </Typography>
                  <div className="bg-white p-8 flex justify-between items-center">
                    <Typography id="reviewUrl" className="pl-8">
                      {`[${t('inviteReviews.message.reviewUrl.name')}]`}
                    </Typography>
                    <Button
                      className="p-0 min-w-28 min-h-0 hover:bg-white max-h-20 h-20"
                      onClick={() => handleCopyClick('reviewUrl')}
                    >
                      <ContentCopyRoundedIcon className="text-quick-campaign" />
                    </Button>
                    {copyLink && copyName === 'reviewUrl' && (
                      <span className="absolute bg-quick-campaign text-white text-12 px-8 rounded top-20 -right-28 text-center duration-300 animate-bounce">
                        {t('business.selectedBusinessDetail.labels.copied')}
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
          {createCampaignDetails?.platForm === 2 && (
            <div className="border !border-gray-500 bg-white border-solid rounded-md p-2">
              <TextField
                className="w-full font-semibold"
                value={createCampaignDetails?.messages}
                onChange={(e) =>
                  setCreateCampaignDetails({
                    ...createCampaignDetails,
                    messages: e.target.value,
                  })
                }
                rows={5}
                multiline
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    fontWeight: '600',
                    lineHeight: '22px',
                    fontSize: 14,
                  },
                }}
                placeholder={t('quickCampaign.template.typeMessage')}
              />
              {createCampaignDetails?.selectedCategoryId === 14 && (
                <Typography className="max-content bg-gray-A500 rounded-md mb-16 mx-12 p-8">
                  {businessDetails?.businessQRId}
                </Typography>
              )}
            </div>
          )}
          {createCampaignDetails?.platForm === 1 && (
            <CKEditor
              activeClass="editor"
              config={{
                allowedContent: true,
                versionCheck: false,
              }}
              content={createCampaignDetails?.htmlTemplate || ''}
              events={{
                change: handleEmailContent,
              }}
            />
          )}
          {createCampaignDetails?.platForm === 3 && (
            <div className="mt-16 rounded-md h-312 overflow-scroll">
              <Typography
                className="mt-8 w-full"
                dangerouslySetInnerHTML={{ __html: createCampaignDetails?.htmlTemplate }}
              />
            </div>
          )}
          <div>
            <Typography className="font-medium my-12 text-14  ">
              {t('quickCampaign.campaign.channel')}
            </Typography>
            <div className="border rounded-md !border-gray border-solid p-8">
              {createCampaignDetails?.platForm === 1 && (
                <Typography className="text-16 font-semibold">
                  <EmailIcon className="text-black text-20 mx-6" />
                  {t('quickCampaign.template.email')}
                </Typography>
              )}
              {createCampaignDetails?.platForm === 2 && (
                <Typography className="text-16 font-semibold">
                  <MessageIcon className="text-black text-20 mx-6" />
                  {t('quickCampaign.template.sms')}
                </Typography>
              )}
              {createCampaignDetails?.platForm === 3 && (
                <Typography className="text-16 font-semibold">
                  <WhatsAppIcon className="text-black text-20 mx-6" />
                  {t('quickCampaign.template.whatsapp')}
                </Typography>
              )}
            </div>
          </div>
          {stepsCount === 1 ? (
            <div className="text-end mt-16">
              <Button
                variant="contained"
                color="quickCampaign"
                autoFocus
                onClick={() => checkCampaignNameAvailable()}
                size="medium"
                disabled={
                  !campaignName ||
                  (createCampaignDetails?.platForm === 2 && !createCampaignDetails?.messages) ||
                  (createCampaignDetails?.platForm === 1 &&
                    !createCampaignDetails?.htmlSubject &&
                    !createCampaignDetails?.htmlSubject) ||
                  !createCampaignDetails?.title ||
                  refreshIcon
                }
                className={`rounded-md px-60 ${
                  refreshIcon
                    ? 'disabled:bg-gray disabled'
                    : 'disabled:opacity-40 disabled:bg-quick-campaign disabled:text-white'
                }`}
              >
                {t('quickCampaign.campaign.selectContacts')}
                {refreshIcon && (
                  <CircularProgress className="text-darkgreen absolute mx-auto" size={24} />
                )}
              </Button>
            </div>
          ) : (
            <div className="text-end mt-24">
              <Button
                variant="outlined"
                color="quickCampaign"
                autoFocus
                className="rounded-md gap-10 md:px-76 md:py-12 mr-8"
                disabled={contacts?.length === 0}
                onClick={() => setIsSchedule(true)}
                size="medium"
              >
                {t('quickCampaign.campaign.schedule')}
              </Button>
              <Button
                variant="contained"
                color="quickCampaign"
                autoFocus
                onClick={() => saveCampaignDraft()}
                size="medium"
                disabled={refreshIcon || contacts?.length === 0}
                className={`rounded-md md:px-76 ${
                  refreshIcon
                    ? 'disabled:bg-gray disabled'
                    : 'disabled:opacity-40 disabled:bg-quick-campaign disabled:text-white'
                }`}
              >
                {t('quickCampaign.campaign.createNow')}
                {refreshIcon && (
                  <CircularProgress className="text-darkgreen absolute mx-auto" size={24} />
                )}
              </Button>
            </div>
          )}
        </div>
      </div>
      <Dialog
        open={isSchedule}
        classes={{
          paper: `m-24 px-24 py-16 border-t-8 border-solid border-t-quick-campaign border-quick-campaign rounded-md`,
        }}
        maxWidth="sm"
      >
        <div className="flex items-center justify-between">
          <h3 className="text-16 font-bold">Schedule Post</h3>
          <div>
            <IconButton className="" onClick={() => setIsSchedule(false)} color="inherit">
              <CloseIcon className="text-24 text-grey-600" />
            </IconButton>
          </div>
        </div>
        <DialogContent className="p-0 mb-24 min-w-320">
          <div className=" items-center justify-between gap-16">
            <div className="">
              <Typography className="text-14 text-black mt-4 font-medium text-left">
                {t('sendMessage.selectDate')}
              </Typography>
              <div className="mt-4">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    className="w-full select-date"
                    id="note"
                    variant="outlined"
                    fullWidth
                    disablePast
                    value={scheduledDate}
                    format="MMM dd, yyyy"
                    onChange={handleDateChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select a date"
                        sx={{
                          '& .MuiInputAdornment-root svg': {
                            color: 'blue',
                          },
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className="">
              <Typography className="text-14 text-black mt-4 font-medium text-left">
                {t('sendMessage.selectTime')}
              </Typography>
              <div className="mt-4">
                <TimePicker
                  className="w-full"
                  id="time"
                  onChange={handleTimeChange}
                  size="small"
                  required
                  value={scheduledTime}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                />
              </div>
            </div>
          </div>
        </DialogContent>

        <DialogActions className="p-0 flex justify-end gap-14">
          <Button
            className="md:text-16 font-medium rounded-md bg-quick-campaign px-60 disabled:bg-gray"
            variant="contained"
            color="secondary"
            onClick={() => handleScheduleDateAndTime()}
            disabled={!scheduledDate || !scheduledTime || refreshIcon}
          >
            {t('quickCampaign.campaign.schedule')}
            {refreshIcon && (
              <CircularProgress className="text-darkgreen absolute mx-auto" size={24} />
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={campaignCreateSuccessFully}
        classes={{
          paper: `m-24 p-36 rounded-sm`,
        }}
        maxWidth="sm"
      >
        <DialogContent className="p-0 mb-24 flex justify-center items-center">
          <div className="text-center">
            <img className="m-auto w-144" src="assets/images/business/campaignsuceess.png" alt="" />
            <Typography className="text-quick-campaign font-semibold text-16 py-8">
              {scheduleDateAndTime
                ? t('quickCampaign.campaign.campaignSchedule')
                : t('quickCampaign.campaign.campaignCreate')}
            </Typography>
            <Typography className="max-w-400 m-auto font-medium">
              {scheduleDateAndTime
                ? t('quickCampaign.campaign.campaignScheduleMessage')
                : t('quickCampaign.campaign.campaignCreateMessage')}
            </Typography>
          </div>
        </DialogContent>

        <DialogActions className="p-0 flex justify-center gap-14">
          <Button
            className="md:text-16 font-medium rounded-md bg-quick-campaign px-60"
            variant="contained"
            color="secondary"
            onClick={() => {
              setCampaignCreateSuccessFully(false);
              history.push('quick-campaign/campaign');
            }}
            disabled={!scheduledDate || !scheduledTime}
          >
            {t('quickCampaign.campaign.backToHome')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CampaignDetails;
