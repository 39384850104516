import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import history from '@history';
import { useSnackbar } from 'notistack';
import { Button } from '@mui/material';
import { t } from 'i18next';
import { getDecryptData, handleApiRequest } from '../../common/common';
import paymentData from '../../query/payment';

const OneTimePayment = () => {
  const routeParams = useLocation();
  const [razorpayInstance, setRazorPayInstance] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [paymentFail, setPaymentFail] = useState(false);
  const redirectFailUrl = `${process.env.REACT_APP_PORTAL_URL}/selectProduct`;

  const routeToken = useCallback(() => {
    try {
      return JSON.parse(getDecryptData(routeParams?.search?.replace('?token=', '')));
    } catch (error) {
      enqueueSnackbar('Invalid token provided', { variant: 'error', autoHideDuration: 3000 });
      return null;
    }
  }, [routeParams, enqueueSnackbar]);

  const loadScript = (src) =>
    new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => resolve(true);
      script.onerror = () => reject(new Error('Razorpay SDK failed to load.'));
      document.body.appendChild(script);
    });

  const handleRazorpaySubscription = useCallback(async () => {
    const isMobile = /iPhone|Android/i.test(navigator.userAgent);
    const isSafari =
      navigator.vendor &&
      navigator.vendor.indexOf('Apple') > -1 &&
      navigator.userAgent &&
      navigator.userAgent.indexOf('CriOS') === -1 &&
      navigator.userAgent.indexOf('FxiOS') === -1;
    try {
      await loadScript('https://checkout.razorpay.com/v1/checkout.js');
    } catch (error) {
      console.error(error.message);
      enqueueSnackbar('Failed to load Razorpay SDK. Please check your connection.', {
        variant: 'error',
        autoHideDuration: 3000,
      });
      return;
    }

    if (!routeToken() || razorpayInstance) return;

    const options = {
      key: process.env.REACT_APP_RAZORPAY_PUBLIC_KEY,
      order_id: routeToken()?.orderId,
      name: 'Quick Reviews',
      prefill: {
        name: routeToken()?.fullName,
        email: routeToken()?.email,
      },
      theme: {
        color: '#0BA861',
      },
      handler: async (response) => {
        try {
          const payload = {
            query: paymentData.validateWebhookSignature,
            variables: {
              razorpayOrderId: response?.razorpay_order_id,
              razorpayPaymentId: response?.razorpay_payment_id,
              razorpaySignature: response?.razorpay_signature,
            },
          };
          const result = await handleApiRequest(payload);

          if (result?.validateWebhookSignature?.status === 200) {
            if (isMobile || isSafari) {
              window.location.href = 'subscription://quickreviewsSubscriptionSuccess';
            } else {
              window.location.href = `${process.env.REACT_APP_PORTAL_URL}/mySubscription`;
            }
          } else {
            throw new Error(
              result?.validateWebhookSignature?.message || 'Payment validation failed.'
            );
          }
        } catch (error) {
          enqueueSnackbar(error.message, { variant: 'error', autoHideDuration: 3000 });
        }
      },
      modal: {
        confirm_close: true,
        ondismiss: (reason) => {
          console.log('Payment closed', reason);
          if (isMobile || isSafari) {
            const failUrl = 'subscriptionfail://quickreviewsSubscriptionFail';
            history.push(failUrl);
          } else {
            setPaymentFail(true);
          }
        },
      },
    };

    const paymentObject = new window.Razorpay(options);
    setRazorPayInstance(paymentObject);

    paymentObject.on('payment.failed', (response) => {
      console.error('Payment failed:', response);
      enqueueSnackbar('Payment failed. Please try again.', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    });

    paymentObject.open();
  }, [razorpayInstance, enqueueSnackbar, routeToken]);

  useEffect(() => {
    handleRazorpaySubscription();
  }, [handleRazorpaySubscription]);

  const handleRetry = () => {
    window.location.href = redirectFailUrl;
  };

  return (
    <>
      {paymentFail && (
        <div className="flex justify-center text-center items-center mt-28">
          <div>
            <div className="">
              <img className="m-auto w-160" src="/assets/images/icon/fail.svg" alt="" />
            </div>
            <h2 className="font-bold my-8">{t('payment.paymentFailed')}</h2>
            <p className="max-w-384 m-auto font-normal">{t('payment.paymentFailMessage')}</p>
            <Button
              variant="contained"
              className="bg-quick-review text-white font-semibold rounded-md text-16 hover:bg-quick-review px-84 mt-20"
              onClick={() => handleRetry()}
            >
              {t('payment.retry')}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default OneTimePayment;
